<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
#app {
  width: 100%;
  overflow-x: hidden !important;
}
.v-dialog--fullscreen {
  overflow-x: hidden;
}
.v-dialog {
  overflow-x: hidden;
}
.v-sheet.v-card {
  border-radius: 0 !important;
}
::-webkit-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  width: 10px;
  background-color: #ffffff;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  box-shadow: inset 0 0 2px 2px #acacac;
  border: solid 3px transparent;
}
/* テキストの末尾を...表示にする */
.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>

<script>
export default {
  metaInfo: {
    title: 'SE-Hub',
    titleTemplate: '%s | Main',
    meta: [
      { vmid: 'description', name: 'description',
        content: '今よりもっと効率よく「エンジニアを集める」「案件情報を探す」 を実現するサービス。 （機能の制限や有料プラン等は一切ありません。）'
      },
    ]
  },
  /* vue-metaを使用すれば、以下不要な可能性あり */
  //routerで設定したタイトルとめたタグを反映する
  methods: {
    createTitleDesc: function (routeInstance) {
      // タイトルを設定
      if (routeInstance.meta.title) {
        let setTitle = routeInstance.meta.title;
        document.title = setTitle;
      } else {
        document.title = 'title is not set'
      }
    }
  },
  mounted: function () {
    let routeInstance = this.$route;
    this.createTitleDesc(routeInstance);
  },
  watch: {
    '$route'(routeInstance) {
      this.createTitleDesc(routeInstance);
    }
  }
}
</script>