<template>
  <div>
    <v-row no-gutters class="ma-2 mb-0 pa-1 px-4 pb-0">
      <v-col>
        <v-text-field
            :label="exploreLabel"
            :color="exploreColor"
            prepend-inner-icon="mdi-magnify"
            v-model="searchString"
            clearable
            @keyup.enter="sendSearchString()"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: "BpExplore",
  data() {
    return {
      exploreColor: 'green accent-4',
      searchString: ''
    }
  },
  props: {
    exploreLabel: String
  },
  methods: {
    sendSearchString() {
      this.$emit('enterSearchForm', this.searchString)
    }
  }
}
</script>

<style scoped>
</style>