<template>
  <v-dialog
      v-model="openedAdvertisingDialog"
      @click:outside="closeThisDialog()"
      max-width="512"
  >
    <v-card class="pa-10">
      <v-card-title class="headline" style="text-align: center; display: inherit;">
        広告情報
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "NaviDialogAdvertising",
  data() {
    return {
    }
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedAdvertisingDialog: Boolean
  },
  methods: {
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog() {
      this.$emit('closeAdvertisingDialog');
    }
  }
}
</script>

<style scoped>
</style>