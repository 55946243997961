<template>
  <v-dialog
      v-model="openedDiscloseDialog"
      @click:outside="closeThisDialog()"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="600"
  >
    <v-card class="pa-10">
      <v-card-title
          class="headline"
          :style="$vuetify.breakpoint.xs ? 'text-align: center;' : ''">
        <p class="ma-0 pa-0 pl-2" style="width: 100%;"
           :style="$vuetify.breakpoint.xs ? 'width: 100%; text-align: center;' : ''">
          <v-icon @click="closeThisDialog" v-if="$vuetify.breakpoint.xs"
                  :style="$vuetify.breakpoint.xs ? 'position: absolute; top: 18px; left: 18px;' : ''">
            mdi-close-thick
          </v-icon>
          情報発信
        </p>
      </v-card-title>
      <v-row class="ma-0 pa-0" :class="$vuetify.breakpoint.xs ? 'ml-2' : 'ml-2'">
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <v-switch
              v-model="selectedBpOnly"
              :color="switchColor"
              :class="$vuetify.breakpoint.xs ? 'small-label py-0' : ''"
              inset
              :dense="$vuetify.breakpoint.xs"
              :label="'BP限定公開'"
          />
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <v-select
              ref="tabForm"
              v-model="selectedTag"
              required
              :rules="rules.discloseTagItems"
              :items="discloseTagItems"
              :color="formColor"
              :class="$vuetify.breakpoint.xs ? 'py-0' : ''"
              label="情報種別を選択して下さい"
          />
        </v-col>
      </v-row>
      <v-row :class="$vuetify.breakpoint.xs ? 'mx-4 my-0 pa-0' : 'ma-0 pa-0'">
        <v-text-field
            ref="titleForm"
            required
            :rules="rules.title"
            label="タイトル"
            :color="formColor"
            :counter="60"
            :maxlength="60"
            v-model="disclosureData.title"
        />
      </v-row>
      <v-row :class="$vuetify.breakpoint.xs ? 'mx-4 my-0 pa-0' : 'ma-0 pa-0'">
        <v-textarea
            ref="bodyForm"
            required
            :rules="rules.body"
            label="内容"
            name="input-7-4"
            v-model="disclosureData.body"
            :color="formColor"
            :counter="1200"
            :maxlength="1200"
            rows="8"
        />
      </v-row>
      <v-row :class="$vuetify.breakpoint.xs ? 'ma-0 pa-4' : 'ma-0 pa-0'">
        <v-col class="ma-0 pa-0"
               :cols="$vuetify.breakpoint.xs
                ? disclosureData.file.length === 0 ? 6 : 12
                : 8">
          <v-file-input
              truncate-length="0"
              label="ファイルを選択"
              v-model="disclosureData.file"
              color="green accent-4"
              @change="fileSizeCheck"
          />
          <span v-if="invalidFileSize && disclosureData.file"
                class="ma-0 red--text"
                :style="$vuetify.breakpoint.xs ? 'font-size: 12px;' : 'font-size: 14px;'">
            ※ファイルサイズが超過しています(最大3MB)
          </span>
        </v-col>
        <v-col class="ma-0 pa-0" style="text-align: right;"
               :cols="$vuetify.breakpoint.xs
                ? disclosureData.file.length === 0 ? 6 : 12
                : 4">
          <v-btn
              @click="disclose()"
              :disabled="!selectedTag || !disclosureData.title || !disclosureData.body || invalidFileSize"
              width=120
              :color="btnColor"
              class="px-8 mt-4"
              :class="btnFontColor"
          >
            送信
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import {mapMutations, mapState} from 'vuex';


export default {
  name: "NaviDialogDisclose",
  data() {
    return {
      /* 情報タグ */
      discloseTagItems: [
        {'text': '案件情報', 'value': '1'},
        {'text': '人材情報', 'value': '2'},
        {'text': '交流会', 'value': '3'},
        {'text': 'その他', 'value': '5'}],
      /* 入力フォーム関連 */
      selectedBpOnly: false,
      selectedTag: '',
      disclosureData: {
        title: '',
        body: '',
        file: []
      },
      /* フォーム入力規制 */
      rules: {
        discloseTagItems: [
          v => !!v || '情報種別は必須です',
        ],
        title: [
          v => !!v || 'タイトルは必須です',
        ],
        body: [
          v => !!v || '内容は必須です',
        ]
      },
      /* ファイルサイズが有効かの状態 */
      invalidFileSize: false,
      /* Style関連 */
      switchColor: 'green accent-4',
      checkBoxColor: 'green accent-4',
      formColor: 'green accent-4',
      btnColor: 'green accent-4',
      btnFontColor: 'white--text',
      errMessageColor: 'red--text',
      /* REST API 関連*/
      restApiBasePath: '',
      restApiDisclosurePath: '/api/disclosure/',
      restApiDiscloseFilePath: '/api/disclosure/file/'
    }
  },
  components: {},
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedDiscloseDialog: Boolean
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token', 'userId', 'globalMainReload'])
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* Vuex展開 */
    ...mapMutations([
      'setGlobalMainReload',
    ]),

    /* 案件情報投稿処理 */
    disclose() {
      // 公開範囲の設定
      let limit = '0';
      if (this.selectedBpOnly) {
        limit = '2'
      }
      const reqBody = {
        'title': this.disclosureData.title,
        'description': this.disclosureData.body,
        'kind': this.selectedTag,
        'limit': limit,
        'data': '',
        'user_id': this.userId,
      };

      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };

      axios.post(this.restApiBasePath + this.restApiDisclosurePath, reqBody, reqHeader).then(res => {
        // JWTログイン後にユーザー情報を取得する
        if (res.status.toString() === '200') {
          if (this.disclosureData.file.length === 0) {
            this.reload();
          } else {
            this.sendFile(res.data.disclosure_id);
          }
        }
      }).catch(e => {
        console.log(e.message);
      });
    },
    /* 添付ファイル投稿処理 */
    sendFile(disclosureId) {
      let form = new FormData();
      let file = this.disclosureData.file;
      form.append('file', file);
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      axios.put(this.restApiBasePath + this.restApiDiscloseFilePath + disclosureId + '/', form, reqHeader).then(res => {
        if(res.status.toString() === '200') {
          this.reload();
        }
      }).catch(e => {
        console.log(e.message);
      });
    },
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog() {
      this.$emit('doneDisclosure', false)
    },
    /* アップロードファイルのサイズチェック */
    fileSizeCheck() {
      // ファイル削除時にundefinedではなく[]を設定する
      if (!this.disclosureData.file) {
        this.disclosureData.file = [];
      }
      // ファイルサイズ（制限）
      const maxSize = 1024 * 1024 * 3;
      // ファイルサイズが制限内かどうか判別
      this.invalidFileSize = this.disclosureData.file.size > maxSize;
    },
    /* フォームリセットとリストリロード処理 */
    reload() {
      this.$refs.tabForm.resetValidation();
      this.$refs.titleForm.resetValidation();
      this.$refs.bodyForm.resetValidation();
      this.selectedTag='';
      this.disclosureData.title = '';
      this.disclosureData.body = '';
      this.disclosureData.file = [];
      this.setGlobalMainReload(!this.globalMainReload);
      this.closeThisDialog();
    }
  }
}
</script>

<style scoped>
/* タイトルフォント */
.card-title {
  text-align: center;
  color: #00C853;
  font-family: 'Franklin Gothic Medium' !important;
  display: inherit;
}

/* エラーメッセージ位置調整 */
.card-text-err {
  text-align: center;
}

/* ボタン内のアイコンサイズ調整 */
.icon {
  font-size: 18px;
}
</style>

<style>
.v-input--switch.small-label .v-label {
  font-size: 14px;
}
</style>