<template>
  <v-dialog
      v-model="openedTermsDialog"
      @click:outside="closeThisDialog()"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="676"
  >
    <v-card class="pa-10">
      <v-card-title class="headline" style="text-align: center; display: inherit;">
        <v-icon @click="closeThisDialog" v-if="$vuetify.breakpoint.xs"
                :style="$vuetify.breakpoint.xs ? 'position: absolute; top: 20px; left: 18px;' : ''">
          mdi-close-thick
        </v-icon>
        利用規約
      </v-card-title>
      <v-card-text>
        <p>この利用規約（以下，「本規約」といいます。）は，株式会社Amayz（以下，「当社」といいます。）が
          このウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。
          登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
        </p>
        <h6>第1条（適用）</h6>
        <ol>
          <li><p>本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。</p></li>
        </ol>
        <h6>第2条（利用登録）</h6>
        <ol>
          <li><p>本サービスにおいては，登録希望者が本規約に同意の上，
            当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，
            利用登録が完了するものとします。</p></li>
          <li><p>当社は，利用登録の申請者に以下の事由があると判断した場合，
            利用登録の申請を承認しないことがあり，
            その理由については一切の開示義務を負わないものとします。</p></li>
        </ol>
        <div class="inner">
          <ol>
            <li><p>利用登録の申請に際して虚偽の事項を届け出た場合</p></li>
            <li><p>本規約に違反したことがある者からの申請である場合</p></li>
            <li><p>その他，当社が利用登録を相当でないと判断した場合</p></li>
          </ol>
        </div>
        <h6>第3条（ユーザーIDおよびパスワードの管理）</h6>
        <ol>
          <li><p>ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを
            適切に管理するものとします。</p></li>
          <li><p>ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを
            第三者に譲渡または貸与し，もしくは第三者と共用することはできません。
            当社は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，
            そのユーザーIDを登録しているユーザー自身による利用とみなします。</p></li>
          <li><p>ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は，
            当社に故意又は重大な過失がある場合を除き，当社は一切の責任を負わないものとします。</p></li>
        </ol>
        <h6>第4条（禁止事項）</h6>
        <p>ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。</p>
        <ol>
          <li><p>法令または公序良俗に違反する行為</p></li>
          <li><p>犯罪行為に関連する行為</p></li>
          <li><p>本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為</p></li>
          <li><p>当社，ほかのユーザー，またはその他第三者のサーバーまたは
            ネットワークの機能を破壊したり，妨害したりする行為</p></li>
          <li><p>本サービスによって得られた情報を商業的に利用する行為</p></li>
          <li><p>当社のサービスの運営を妨害するおそれのある行為</p></li>
          <li><p>不正アクセスをし，またはこれを試みる行為</p></li>
          <li><p>本サービスに対しスクレイピング、データ抽出またはその他の方法で情報を収集する行為</p></li>
          <li><p>他のユーザーに関する個人情報等を収集または蓄積する行為</p></li>
          <li><p>不正な目的を持って本サービスを利用する行為</p></li>
          <li><p>本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為</p></li>
          <li><p>他のユーザーに成りすます行為</p></li>
          <li><p>当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為</p></li>
          <li><p>面識のない異性との出会いを目的とした行為</p></li>
          <li><p>当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為</p></li>
          <li><p>その他，当社が不適切と判断する行為</p></li>

        </ol>
        <h6>第5条（本サービスの提供の停止等）</h6>
        <ol>
          <li>
            <p>当社は，以下のいずれかの事由があると判断した場合，
              ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または
              中断することができるものとします。</p>
            <div class="inner">
              <ol>
                <li><p>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</p></li>
                <li><p>地震，落雷，火災，停電または天災などの不可抗力により，
                  本サービスの提供が困難となった場合</p></li>
                <li><p>コンピュータまたは通信回線等が事故により停止した場合</p></li>
                <li><p>その他，当社が本サービスの提供が困難と判断した場合</p></li>
              </ol>
            </div>
          </li>
          <li><p>当社は，本サービスの提供の停止または中断により，
            ユーザーまたは第三者が被ったいかなる不利益または損害についても，
            一切の責任を負わないものとします。</p></li>
        </ol>
        <h6>第6条（利用制限および登録抹消）</h6>
        <ol>
          <li>
            <p>当社は，ユーザーが以下のいずれかに該当する場合には，
              事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，
              またはユーザーとしての登録を抹消することができるものとします。</p>
            <div class="inner">
              <ol>
                <li><p>本規約のいずれかの条項に違反した場合</p></li>
                <li><p>登録事項に虚偽の事実があることが判明した場合</p></li>
                <li><p>当社からの連絡に対し，一定期間返答がない場合</p></li>
                <li><p>本サービスについて，最終の利用から一定期間利用がない場合</p></li>
                <li><p>その他，当社が本サービスの利用を適当でないと判断した場合</p></li>
              </ol>
            </div>
          </li>
          <li><p>当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，
            一切の責任を負いません。</p></li>
        </ol>
        <h6>第7条（退会）</h6>
        <p>ユーザーは，当社の定める退会手続により，本サービスから退会できるものとします。</p>
        <h6>第8条（保証の否認および免責事項）</h6>
        <ol>
          <li><p>当社は，本サービスに事実上または法律上の瑕疵
            （安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，
            エラーやバグ，権利侵害などを含みます。）がないことを明示的にも
            黙示的にも保証しておりません。</p></li>
          <li><p>当社は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。</p></li>
          <li><p>当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，
            連絡または紛争等について一切責任を負いません。</p></li>
        </ol>
        <h6>第9条（サービス内容の変更等）</h6>
        <p>当社は，ユーザーに通知することなく，本サービスの内容を変更しまたは
          本サービスの提供を中止することができるものとし，
          これによってユーザーに生じた損害について一切の責任を負いません。</p>
        <h6>第10条（利用規約の変更）</h6>
        <p>当社は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。
          なお，本規約の変更後，本サービスの利用を開始した場合には，
          当該ユーザーは変更後の規約に同意したものとみなします。</p>
        <h6>第11条（個人情報の取扱い）</h6>
        <p>当社は，本サービスの利用によって取得する個人情報については，
          当社「プライバシーポリシー」に従い適切に取り扱うものとします。</p>
        <h6>第12条（通知または連絡）</h6>
        <p>ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。
          当社は,ユーザーから,当社が別途定める方式に従った変更届け出がない限り,
          現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,
          これらは,発信時にユーザーへ到達したものとみなします。</p>
        <h6>第13条（権利義務の譲渡の禁止）</h6>
        <p>ユーザーは，当社の書面による事前の承諾なく，
          利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，
          または担保に供することはできません。</p>
        <h6>第14条（準拠法・裁判管轄）</h6>
        <ol>
          <li><p>本規約の解釈にあたっては，日本法を準拠法とします。</p></li>
          <li><p>本サービスに関して紛争が生じた場合には，
            当社の本店所在地を管轄する裁判所を専属的合意管轄とします。</p></li>
        </ol>
        <p style="text-align: right;">以上</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "NaviDialogTerms",
  data() {
    return {
    }
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedTermsDialog: Boolean
  },
  methods: {
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog() {
      this.$emit('closeTermsDialog');
    }
  }
}
</script>

<style scoped>
</style>