<template>
  <v-dialog
      v-model="openedSignUpDialog"
      @click:outside="closeThisDialog()"
      :content-class="$vuetify.breakpoint.xs ? 'mx-0' : ''"
      max-width="512"
  >
    <v-card class="pa-10">
      <v-card-title class="headline" style="text-align: center; display: inherit;">
        登録情報入力
      </v-card-title>
      <v-form ref="form" v-model="valid" :class="$vuetify.breakpoint.xs ? 'pl-2 pr-4 pt-4 pb-6' : 'pl-6 pr-8 pt-4 pb-6'">
        <v-text-field
            @keyup.enter="focusMailForm"
            v-model="credentials.name"
            required
            :rules="rules.name"
            :counter="30"
            maxlength="30"
            label="ユーザー名"
            color="green accent-4"
            prepend-icon="mdi-account-circle"
        />
        <v-text-field
            @keyup.enter="focusPassForm"
            v-model="credentials.email"
            ref="mailForm"
            required
            :rules="rules.email"
            :counter="50"
            maxlength="50"
            label="メールアドレス"
            :color="formColor"
            prepend-icon="mdi-account-circle"
        />
        <v-text-field
            @keyup.enter="sginUp"
            @click:append="shownPassword = !shownPassword"
            v-model="credentials.password"
            ref="passForm"
            required
            :rules="rules.password"
            :counter="20"
            maxlength="20"
            label="パスワード"
            :color="formColor"
            prepend-icon="mdi-lock"
            :append-icon="shownPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="shownPassword ? 'text' : 'password'"
        />
      </v-form>
      <v-card-text v-if="errorMessage" class="red--text" style="text-align: center;">
        {{ this.errorMessage }}
      </v-card-text>
      <v-card-actions class="px-8">
        <v-col style="text-align: center;">
          <v-btn
              @click="sginUp"
              :disabled="!valid"
              :color="btnColor"
              :class="btnFontColor"
              width="180"
          >
            入力情報送信
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
    <NaviDialogSetHash
        :openedSetHashDialog="openedSetHashDialog"
        :credentials="credentials"
        @closeSetHashDialog="closeSetHashDialog"
    />
  </v-dialog>
</template>

<script>
import axios from 'axios';
import NaviDialogSetHash from '@/components/molecules/navi/NaviDialogSetHash.vue'

export default {
  name: "NaviDialogSignUp",
  data() {
    return {
      /* フォーム入力値 */
      credentials: {
        name: '',
        email: '',
        password: ''
      },
      /* フォーム群入力状態管理 */
      valid: false,
      /* フォーム入力規制 */
      rules: {
        name: [
          v => !!v || 'ユーザー名は必須です',
          v => (v && v.length > 0) || 'ユーザー名を入力してください'
        ],
        email: [
          v => !!v || 'メールアドレスは必須です',
        ],
        password: [
          v => !!v || 'パスワードは必須です',
          v => (v && v.length > 4) || 'パスワードは5文字以上でなければなりません'
        ]
      },
      /* パスワードフォーム表示状態管理 */
      shownPassword: false,
      /* エラーメッセージ保存 */
      errorMessage:'',
      /* Style関連 */
      formColor: 'green accent-4',
      btnColor: 'green accent-4',
      btnFontColor: 'white--text',
      /* REST API 関連*/
      restApiBasePath: '',
      restApiHashPath: '/api/user/hash/',
      /* ハッシュダイアログの状態管理 */
      openedSetHashDialog: false

    }
  },
  components: {
    NaviDialogSetHash
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedSignUpDialog: Boolean
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* メールアドレスフォームへ移動する処理 */
    focusMailForm() {
      this.$refs.mailForm.focus();
    },
    /* パスワードフォームへ移動する処理 */
    focusPassForm() {
      this.$refs.passForm.focus();
    },
    /* サインアップ処理 */
    sginUp() {
      /* フォーム未入力の場合処理を行わずリターン */
      if (!this.valid) {
        return;
      }
      /* 全てのバリデーションチェック */
      if (this.$refs.form.validate()) {
        /* ハッシュキー発行処理呼び出し */
        this.requestHash();
      }
    },
    /* ハッシュキー発行依頼処理 */
    requestHash() {
      /* バックエンド向けのデータ作成 */
      const reqBody = {
        'email': this.credentials.email
      };
      /* バックエンドのAPI呼び出し */
      axios.post(this.restApiBasePath + this.restApiHashPath, reqBody).then(res =>{
        if(res.status !== 200) {
          this.errorMessage = res.data.message
        } else {
          this.errorMessage = '';
          /* ハッシュキー入力ダイアログのオープン */
          this.openedSetHashDialog = true;
        }
      })
      .catch(e => {
        this.errorMessage = e.response.data.message;
      });
    },
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog(doneSignUp=false) {
      this.errorMessage = '';
      if (doneSignUp) {
        this.$emit('closeSignUpDialog', this.credentials);
        this.$refs.form.reset()
      } else {
        this.$emit('closeSignUpDialog', '')
      }
    },
    /* ハッシュキー入力ダイアログのクローズ */
    closeSetHashDialog(doneSignUp) {
      this.openedSetHashDialog = false;
      if (doneSignUp) {
        this.closeThisDialog(doneSignUp);
      }
    }
  }
}
</script>

<style scoped>
  /* タイトルフォント */
  .card-title {
    text-align: center;
    display: inherit;
  }

  /* エラーメッセージ位置調整 */
  .card-text-err {
    text-align: center;
  }

  /* ボタン内のアイコンサイズ調整 */
  .icon {
    font-size: 18px;
  }
</style>