<template>
  <div>
    <v-row class="pa-0 ma-0">
      <v-tabs
          fixed-tabs
          :color="tabColor"
          v-model="selectedTab"
      >
        <v-tab
            v-for="(tabLabel, index) in tabLabels"
            :key="index"
            :ripple="false"
            :style="($vuetify.breakpoint.xs) ? 'font-size: small; padding: 0;' : ''"
        >
          {{ tabLabel }}
        </v-tab>
        <v-tabs-items
          v-model="selectedTab"
        >
          <v-divider />
          <v-tab-item :transition="false" :reverse-transition="false">
            <BpUserList :searchString="searchString" :selectedTab="selectedTab.toString()" tabType="0" :reload="reload">
              <template slot-scope="{ otherId }">
                <BpRequestBtn :otherId="otherId" @enterBpRequestBtn="reacquireUserList"/>
              </template>
            </BpUserList>
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <BpUserList :searchString="searchString" :selectedTab="selectedTab.toString()" tabType="1" :reload="reload">
              <template slot-scope="{ otherId }">
                <BpCancellationBtn :otherId="otherId" @enterBpCancelBtn="reacquireUserList"/>
              </template>
            </BpUserList>
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <BpUserList :searchString="searchString" :selectedTab="selectedTab.toString()" tabType="2" :reload="reload">
              <template slot-scope="{ otherId }">
                <BpRequestBtn :otherId="otherId" @enterBpRequestBtn="reacquireUserList"/>
              </template>
            </BpUserList>
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <BpUserList :searchString="searchString" :selectedTab="selectedTab.toString()" tabType="3" :reload="reload">
              <template slot-scope="{ otherId }">
                <BpDeletionBtn :otherId="otherId" @enterBpRequestBtn="reacquireUserList"/>
              </template>
            </BpUserList>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BpUserList from '@/components/molecules/bp/BpUserList.vue'
import BpRequestBtn from '@/components/atoms/bp/BpRequestBtn.vue'
import BpCancellationBtn from '@/components/atoms/bp/BpCancellationBtn.vue'
import BpDeletionBtn from '@/components/atoms/bp/BpDeletionBtn.vue'

export default {
  name: "BpMenuBar",
  data() {
    return {
      /* Tab関連 */
      selectedTab: 0,
      tabLabels: [
        'ユーザー',
        '承認待ち',
        'リクエスト',
        'BP一覧'
      ],

      /* リスト関連 */
      userList: [],

      /* 更新通知用フラグ */
      reload: false,

      /* REST API関連 */
      restApiBasePath: '',
      restApiBpListPath: '/api/bp/list/',

      /* Style関連 */
      tabColor: 'green accent-4',
    }
  },
  components: {
    BpUserList,
    BpRequestBtn,
    BpCancellationBtn,
    BpDeletionBtn
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token','userId','globalSearchString'])
  },
  props: {
    /* 親コンポーネントから検索キーワードを受け取り */
    searchString: String
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* 子コンポーネントのボタンイベント受け取り処理 */
    reacquireUserList() {
      /* リストの再取得 */
      this.reload = !this.reload;
    }
  }
}
</script>

<style scoped></style>

<style>
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none !important;
}
</style>