<template>
  <v-container class="ma-0 pa-0">
    <!-- メインメニュー部分 FROM -->
    <v-navigation-drawer
        v-model="openedSpNaviMainMenu"
        width="80%"
        absolute
        temporary
        :stateless="openedLoginDialog
         || openedSettingsDialog
         || openedTermsDialog
         || openedPrivacyDialog"
        style="position: fixed;"
        @click:outside="closeSpNaviMainMenu"
    >
      <v-list
          nav
          dense
          flat
      >
        <v-list-item-group :class="naviFont" v-if="userId">
          <v-list-item :to="'/profile/' + userId" :ripple="false" class="py-2 px-3">
            <v-list-item-avatar class="mr-8" :class="outlined">
              <v-img v-if="userInfo.img" :src="userInfo.img"/>
              <v-img v-else src="@/assets/default_avatar.jpeg"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle
                  v-if="userInfo.groupName"
                  class="font-weight-bold"
                  :style="($route.path === '/profile/' + userId) ? 'color: #00C853;' : 'color: darkslateblue;'"
              >
                {{ userInfo.groupName }}
              </v-list-item-subtitle>
              <v-list-item-title class="font-weight-bold">
                {{ userInfo.name }}
                <span v-if="!userImg" style="font-weight: normal; font-size: 10px;"><br/>プロフィール編集はコチラ</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group v-else>
          <v-list-item @click.stop="openedLoginDialog=true" class="py-2">
            <v-list-item-avatar>
              <v-icon large :color="iconColor">mdi-account</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>ゲスト</v-list-item-title>
              <v-list-item-subtitle>ログイン、新規登録はコチラ</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group :class="naviFont">
          <v-divider class="mt-2 mb-4"></v-divider>
          <v-list-item to="/" :ripple="false" active-class="font-weight-bold" class="pt-3 pb-1">
            <v-list-item-icon class="pl-4 mr-4">
              <v-icon>mdi-magnify</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>検索</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="userId" to="/bp" :ripple="false" active-class="font-weight-bold"
                       class="py-1">
            <v-list-item-icon class="pl-4 mr-4">
              <v-icon>mdi-handshake-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>BP管理</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="userId" to="/message" :ripple="false" active-class="font-weight-bold" class="py-1">
            <v-list-item-icon class="pl-4 mr-4">
              <v-icon>mdi-email-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>メッセージ</v-list-item-title>
              <span v-if="noReadCount > 0">
                <span style="position: absolute; bottom: 20px; right: 12px;">
                  <v-tab>
                    <v-badge
                        color="red"
                        :content="noReadCount"
                    ></v-badge>
                  </v-tab>
                </span>
              </span>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group>
          <v-list-item v-if="userId" @click.stop="openedSettingsDialog=true" :ripple="false" class="py-1">
            <v-list-item-icon class="pl-4 mr-4">
              <v-icon :color="iconColor">mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>設定</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="my-4"></v-divider>
          <v-list-item :ripple="false" active-class="font-weight-bold" class="pt-2">
            <v-list-item-content class="ml-8">
              <v-list-item-title @click="openedTermsDialog = true">
                利用規約
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :ripple="false" active-class="font-weight-bold">
            <v-list-item-content class="ml-8">
              <v-list-item-title @click="openedPrivacyDialog = true">
                プライバシーポリシー
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :ripple="false" active-class="font-weight-bold">
            <v-list-item-content class="ml-8">
              <v-list-item-title>
                <a href="https://amayz.co.jp" style="color: #000000DE; text-decoration: none;">
                  運営会社情報
                </a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :ripple="false" active-class="font-weight-bold">
            <v-list-item-content class="ml-8">
              <v-list-item-title>
                <a href="https://amayz.co.jp/contact/" style="color: #000000DE; text-decoration: none;">
                  お問い合わせ
                </a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group v-if="!userId" class="mt-8">
          <v-img src="@/assets/default_ad.png"
                 class="mb-4"
                 style="width: 200px; margin: 0 auto;"
          >
          </v-img>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- メインメニュー部分 TO -->
    <NaviDialogLogin :openedLoginDialog="openedLoginDialog" @doneAuth="closeLoginDialog"/>
    <NaviDialogDisclose :openedDiscloseDialog="openedDiscloseDialog" @doneDisclosure="closeDiscloseDialog"/>
    <NaviDialogSettings
        :openedSettingsDialog="openedSettingsDialog"
        @doneSettings="closeSettingsDialog"
        @changeSettings="changeSettings"
    />
    <NaviDialogTerms :openedTermsDialog="openedTermsDialog" @closeTermsDialog="closeTermsDialog"/>
    <NaviDialogPrivacy :openedPrivacyDialog="openedPrivacyDialog" @closePrivacyDialog="closePrivacyDialog"/>
    <!-- ヘッダー部分 FROM -->
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0">
        <v-app-bar color="green accent-4" app class="ma-0 pa-0" style="width: 100vw;">
          <v-icon
              color="white"
              style="font-size: 28px; padding-right: 8px;"
              @click.stop="openedSpNaviMainMenu = true"
          >mdi-menu
          </v-icon>
          <span
              v-if="($route.path !== '/') || ($route.path !== '/bp')"
              class="header-logo">
            SE-Hub
          </span>
          <v-text-field
              v-if="($route.path === '/') || ($route.path === '/bp')"
              v-model="searchStr"
              ref="searchStr"
              @keyup.enter="sendSearchString()"
              @click:clear="clearSearchString()"
              class="mx-4 mt-6"
              background-color="#f8f8f8"
              rounded
              single-line
              color="green accent-4"
              :label="$route.path === '/' ? 'キーワード検索' : 'ユーザー検索'"
              clearable
              prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-app-bar>
      </v-col>
    </v-row>
    <!-- ヘッダー部分 TO -->
    <!-- フッター部分 FROM -->
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0">
        <v-footer color="white" app class="ma-0 pa-0 footer">
          <v-container class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="py-2 px-0" style="text-align: center;">
                <v-btn v-if="userId" to="/" text active-class="green--text text--accent-4 no-background"
                       :ripple="false" class="grey--text text--accent-1">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="4" class="py-2 px-0" style="text-align: center;">
                <v-btn v-if="userId" to="/message" text active-class="green--text text--accent-4 no-background"
                       :ripple="false" class="grey--text text--accent-1">
                  <v-icon>mdi-email-outline</v-icon>
                  <span v-if="noReadCount > 0">
                  <span style="position: absolute; bottom: 16px; right: -48px;">
                    <v-tab>
                      <v-badge
                          color="red"
                          :content="noReadCount"
                      ></v-badge>
                    </v-tab>
                  </span>
                </span>
                </v-btn>
                <v-btn v-else to="/" text active-class="green--text text--accent-4 no-background" :ripple="false"
                       class="grey--text text--accent-1">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="4" class="py-2 px-0" style="text-align: center;">
                <v-btn v-if="userId" to="/bp" text active-class="green--text text--accent-4 no-background"
                       :ripple="false"
                       class="grey--text text--accent-1">
                  <v-icon>mdi-handshake-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-footer>
      </v-col>
    </v-row>
    <!-- フッター部分 TO -->
    <!-- 投稿ボタン FROM -->
    <v-btn
        v-if="(userId) && (($route.path === '/') || ($route.path === '/profile/' + userId))"
        class="mx-2"
        color="green accent-4"
        style="position: fixed; bottom: 72px; right: 16px;"
        absolute
        fab
        dark
        large
        @click.stop="openedDiscloseDialog = true"
    >
      <v-icon dark>
        mdi-pencil
      </v-icon>
    </v-btn>
    <!-- 投稿ボタン TO -->
  </v-container>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import axios from "axios";
import NaviDialogLogin from '@/components/molecules/navi/NaviDialogLogin.vue';
import NaviDialogDisclose from "@/components/molecules/navi/NaviDialogDisclose";
import NaviDialogSettings from "@/components/molecules/navi/NaviDialogSettings";
import NaviDialogTerms from "@/components/molecules/navi/NaviDialogTerms";
import NaviDialogPrivacy from "@/components/molecules/navi/NaviDialogPrivacy";

export default {
  name: 'SpNaviHeader',
  components: {
    NaviDialogLogin,
    NaviDialogDisclose,
    NaviDialogSettings,
    NaviDialogTerms,
    NaviDialogPrivacy
  },
  mounted() {
    if (this.userId) {
      this.getUserInfo();
      this.initOutline();
      /* 検索キーワードのリセット */
      this.resetSearchString();
      /* クエリ取得処理 */
      this.getQuery();
      this.getNoReadCount();
      window.setInterval(() => {
        this.getNoReadCount();
      }, 60000);
    } else {
      /* 検索キーワードのリセット */
      this.resetSearchString();
      /* クエリ取得処理 */
      this.getQuery();
    }
  },
  data() {
    return {
      /* アイコンカラー(default) */
      iconColor: 'grey darken-1',
      /* 選択されているメニューの色 */
      naviFont: 'green--text text--accent-4',
      outlined: '',
      /* メニュー（ドロワー）の状態管理 */
      openedSpNaviMainMenu: false,
      /* ダイアログの状態管理 */
      openedLoginDialog: false,
      openedDiscloseDialog: false,
      openedSettingsDialog: false,
      openedTermsDialog: false,
      openedPrivacyDialog: false,
      /* ユーザー情報 */
      userInfo: {
        userId: '',
        token: '',
        name: '',
        img: '',
        groupName: '',
        Key: '',
      },
      /* 設定フラグ */
      settings: {
        messageSendMail: false,
        bpSendMail: false,
        canFindName: false,
      },
      /* 未読メッセージ数 */
      noReadCount: '',
      /* 検索キーワード */
      searchStr: '',
      /* クエリURLアクセス時の一時保存検索キーワード */
      savedSearchStr: ''
    }
  },
  watch: {
    '$route': function () {
      this.initOutline();
      /* ページ遷移時に検索文字列を初期化 */
      this.resetSearchString();
    },
    'globalNaviReload': function () {
      if (this.globalNaviReloadTarget === 'getNoReadCount') {
        this.getNoReadCount();
      }
      if (this.globalNaviReloadTarget === 'getUserInfo') {
        this.getUserInfo();
      }
    }
  },
  methods: {
    /* Vuex展開 */
    ...mapMutations([
      'setGlobalMainReload',
    ]),

    changeSettings(eventVal) {
      if (eventVal[1] === 1) {
        this.settings.messageSendMail = eventVal[0]
      } else if (eventVal[1] === 2) {
        this.settings.bpSendMail = eventVal[0]
      } else if (eventVal[1] === 5) {
        this.settings.canFindName = eventVal[0]
      } else {
        console.log('error')
      }
    },
    closeSpNaviMainMenu() {
      this.openedSpNaviMainMenu = false;
    },
    closeLoginDialog(doneAuth) {
      if (doneAuth) {
        this.getUserInfo();
        this.setGlobalMainReload(!this.globalMainReload);
      }
      this.openedLoginDialog = false;
    },
    closeDiscloseDialog() {
      this.openedDiscloseDialog = false;
    },
    closeSettingsDialog() {
      this.openedSettingsDialog = false;
    },
    /* 利用規約ダイアログのクローズ処理 */
    closeTermsDialog() {
      this.openedTermsDialog = false;
    },
    /* プライバシーポリシーダイアログのクローズ処理 */
    closePrivacyDialog() {
      this.openedPrivacyDialog = false;
    },
    initOutline() {
      if (this.$route.path === '/profile/' + this.userId) {
        this.outlined = 'outlined';
      } else {
        this.outlined = '';
      }
    },
    /* 検索キーワードのリセット */
    resetSearchString() {
      this.clearSearchString();
      /* クエリ取得処理による再リロードの場合、退避した検索キーワードで再検索 */
      if (this.savedSearchStr) {
        this.searchStr = this.savedSearchStr;
        this.setGlobalSearchString(this.searchStr);
        this.savedSearchStr = '';
      }
    },
    /* クエリ取得処理 */
    getQuery() {
      if(Object.keys(this.$route.query).length) {
        this.searchStr = 'ID:' + this.$route.query.ID;
        this.savedSearchStr = this.searchStr;
        this.setGlobalSearchString(this.searchStr);
      }
    },
    // メッセージ未読数取得
    getNoReadCount: function () {
      if (!this.loginState) {
        return;
      }
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      axios.get(process.env.VUE_APP_URL + '/api/message/notice/' + this.userId + '/', reqHeader).then(res => {
        if (res.status.toString() === '200') {
          this.noReadCount = res.data.message_count;
        }
      }).catch(e => {
        console.log(e.message);
      });
    },
    // ユーザ情報取得処理
    getUserInfo() {
      axios.get(process.env.VUE_APP_URL + '/api/user/' + this.userId + '/').then(res => {
        this.userInfo.key = res.data.key;
        this.userInfo.name = res.data.name;
        if (res.data.img) {
          this.userInfo.img = this.basePath + '/media/' + res.data.img + '?' + Math.random();
        } else {
          this.userInfo.img = '';
        }
        this.userInfo.groupName = res.data.group__name;
        this.settings.messageSendMail = res.data.should_send_message;
        this.settings.bpSendMail = res.data.should_send_bp;
        this.settings.canFindName = res.data.can_find_name;
        this.setUserKey(this.userInfo.key);
        this.setUserName(this.userInfo.name);
        this.setUserImg(this.userInfo.img);
        this.setGroupName(this.userInfo.groupName);
      }).catch(e => {
        console.log("error");
        console.log(e.message);
      });
    },
    setLoginState: function (state) {
      this.$store.commit('setLoginState', state);
    },
    setUserName: function (user_name) {
      this.$store.commit('setUserName', user_name);
    },
    setGroupName: function (groupName) {
      this.$store.commit('setGroupName', groupName);
    },
    setUserId: function (userId) {
      this.$store.commit('setUserId', userId);
    },
    setUserKey: function (userKey) {
      this.$store.commit('setUserKey', userKey);
    },
    setUserImg: function (userImg) {
      this.$store.commit('setUserImg', userImg);
    },
    setToken: function (token) {
      this.$store.commit('setToken', token);
    },
    setProfileUserId: function (profileUserId) {
      this.$store.commit('setProfileUserId', profileUserId)
    },
    /* 検索キーワードをVuexに保存する処理 */
    setGlobalSearchString: function (searchString) {
      this.$store.commit('setGlobalSearchString', searchString);
    },
    toProfile() {
      this.setProfileUserId(this.userId);
      this.$router.push('/profile/' + this.userId).catch(err => {
        console.log(err)
      });
      this.selectedProfile = true;
    },
    /* 検索キーワードの送信 */
    sendSearchString() {
      this.setGlobalSearchString(this.searchStr);
      this.$refs.searchStr.blur();
    },
    /* クリアアイコン押下時の検索文字列空での再検索 */
    clearSearchString() {
      this.searchStr = null;
      this.setGlobalSearchString(this.searchStr);
    }
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token', 'userId', 'globalSearchString', 'globalMainReload']),
    loginState: function () {
      return this.$store.state.loginState;
    },
    userName: function () {
      return this.$store.state.userName;
    },
    groupName: function () {
      return this.$store.state.groupName;
    },
    userId: function () {
      return this.$store.state.userId;
    },
    userKey: function () {
      return this.$store.state.userKey;
    },
    userImg: function () {
      return this.$store.state.userImg;
    },
    token: function () {
      return this.$store.state.token;
    },
    profileUserId: function () {
      return this.$store.state.profileUserId;
    },
    /* MainからNaviへの更新管理 */
    globalNaviReload: function () {
      return this.$store.state.globalNaviReload;
    },
    /* MainからNaviへの更新ターゲット情報 */
    globalNaviReloadTarget: function () {
      return this.$store.state.globalNaviReloadTarget
    },
    basePath: function () {
      return process.env.VUE_APP_URL;
    }
  },
}
</script>

<style scoped>
/* 自プロフィール表示時にアイコンを緑色で光らせる */
.outlined {
  border: 2px solid #00C853;
  border-radius: 50%;
  height: 56px;
  width: 56px;
}

/* タイトルフォント */
.header-logo {
  position: absolute;
  left: calc(50vw - 32px);
  color: white;
  font-size: 18px !important;
  font-weight: bold !important;
  font-family: 'Roboto', sans-serif !important;
  display: inherit;
}

/* フッターのボーダー部分 */
.footer {
  width: 100vw;
  border-top: 1px solid #cccccc !important;
}

/* ボタンの背景色を無効化 */
.no-background::before {
  opacity: 0 !important;
}
</style>