<template>
  <v-btn
      @click="setBpRequestInfo(otherId)"
      :color="buttonColor"
      :class="buttonFontColor"
      style="border-width: 2px; position: absolute;"
      :style="$vuetify.breakpoint.xs ? 'right: 16px;' : 'right: 32px;'"
      outlined
      rounded
      height="40"
      width="120"
      max-width="120"
  >
    <b>BP申請する</b>
  </v-btn>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: "BpRequestBtn",
  data() {
    return {
      /* REST API関連 */
      restApiBasePath: '',
      restApiBpPath: '/api/bp/',

      /* Style関連 */
      buttonColor: 'green accent-4',
      buttonFontColor: 'white--text'
    }
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token','userId'])
  },
  props: {
    /* 親コンポーネントから申請相手のユーザIDを受け取り */
    otherId: String
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* BP申請情報送信処理 */
    setBpRequestInfo(otherId) {
      /* バックエンド向けのデータ作成 */
      const reqBody = {
        'user_id': this.userId,
        'other_id': otherId,
      };
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      /* バックエンドのAPI呼び出し */
      axios.post(this.restApiBasePath + this.restApiBpPath, reqBody, reqHeader).then(() => {
        /* 親コンポーネントへ通知 */
        this.notifyButtonEvent()
      }).catch(e => {
        console.log(e.message);
      });
    },
    /* ボタン押下を親コンポーネントへ通知する処理 */
    notifyButtonEvent() {
      this.$emit('enterBpRequestBtn', true)
    }
  }
}
</script>

<style scoped></style>