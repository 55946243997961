<template>
  <v-dialog
      v-model="openedLogoutDialog"
      @click:outside="closeThisDialog()"
      max-width="512"
  >
    <v-card :class="$vuetify.breakpoint.xs ? 'px-0 py-6' : 'px-10 py-6'">
      <v-card-text class="px-6 pt-10 pb-6 card-text">
        ログアウトしますか?
      </v-card-text>
      <v-card-actions class="card-actions">
        <v-col>
          <v-btn
              :color="logoutBtnColor"
              :class="btnFontColor"
              @click="logout()"
          >
            ログアウト
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
              :color="cancelBtnColor"
              :class="btnFontColor"
              @click="closeThisDialog()"
          >
            キャンセル
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';


export default {
  name: "NaviDialogLogout",
  data() {
    return {
      /* Style関連 */
      logoutBtnColor: 'red',
      cancelBtnColor: 'grey',
      btnFontColor: 'white--text',
      /* REST API 関連*/
      restApiBasePath: '',
      restApiAuthPath: '/api/auth/',
    }
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedLogoutDialog: Boolean
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* Vuexのmutationを展開 */
    ...mapMutations([
      'setToken',
      'setUserId',
      'setLoginState'
    ]),
    /* ログアウト処理 */
    logout() {
      /* Vuexの保存値をリセット */
      this.setToken('');
      this.setUserId('');
      this.setLoginState(false);
      /*ダイアログをクローズ*/
      const doneLogout = true;
      this.$emit('closeLogoutDialog', doneLogout)
    },
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog() {
      this.$emit('closeLogoutDialog', false)
    }
  }
}
</script>

<style scoped>
  .card-text {
    font-weight: bold;
    text-align: center;
  }
  .card-actions {
    text-align: center;
  }
</style>