<template>
  <v-dialog
      v-model="openedDelAccountDialog"
      @click:outside="closeThisDialog()"
      max-width="480"
  >
    <v-card :class="$vuetify.breakpoint.xs ? 'px-0 py-6' : 'px-10 py-6'">
      <v-card-text class="px-6 pt-10 pb-6" style="font-weight: bold; text-align: center;">
        アカウントを退会しますか？<br>
        <p class="pt-4 ma-0" style="text-align: left; font-size: 12px;">※法人ドメインのメールアドレスではない場合、過去に登録したことがあるメールアドレスでの再登録は不可能となります。</p>
      </v-card-text>
      <v-card-actions style="text-align: center;">
        <v-col>
          <v-btn
              color="red"
              class="white--text"
              @click="deleteUser()"
          >
            退会する
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
              color="grey"
              class="white--text"
              @click="closeThisDialog()"
          >
            キャンセル
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapState, mapMutations } from 'vuex';


export default {
  name: "NaviDialogDelAccount",
  data() {
    return {
      /* Style関連 */
      logoutBtnColor: 'red',
      cancelBtnColor: 'grey',
      btnFontColor: 'white--text',
      /* REST API 関連*/
      restApiBasePath: '',
      restApiAuthPath: '/api/auth/',
    }
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedDelAccountDialog: Boolean
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token','userId'])
  },
  methods: {
    /* Vuexのmutationを展開 */
    ...mapMutations([
      'setToken',
      'setUserId',
      'setLoginState'
    ]),
    /* 退会処理 */
    deleteUser() {
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      axios.delete(process.env.VUE_APP_URL + '/api/user/' + this.userId + '/', reqHeader).then(res => {
        if (res.status.toString() === '200') {
          this.logout();
        }
      }).catch(e => {
        console.log("error");
        console.log(e.message);
      });
    },
    /* ログイン情報のリセット処理 */
    logout() {
      /* Vuexの保存値をリセット */
      this.setToken('');
      this.setUserId('');
      this.setLoginState(false);
      /*ダイアログをクローズ*/
      const doneLogout = true;
      this.$emit('closeDelAccountDialog', doneLogout)
    },
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog() {
      this.$emit('closeDelAccountDialog', false)
    }
  }
}
</script>

<style scoped>
  .card-text {
    font-weight: bold;
    text-align: center;
  }
  .card-actions {
    text-align: center;
  }
</style>