<template>
  <v-dialog
      v-model="openedLoginDialog"
      @click:outside="closeThisDialog()"
      :content-class="$vuetify.breakpoint.xs ? 'mx-0' : ''"
      :max-width="$vuetify.breakpoint.xs ? '100vw' : '450'"
  >
    <v-card :class="$vuetify.breakpoint.xs ? 'px-0 py-8' : 'px-6 py-10'">
      <v-card-title class="headline card-title">
        SE-Hub
      </v-card-title>
      <v-form ref="form" v-model="valid" class="pl-6 pr-8 pt-4 pb-6">
        <v-text-field
            @keyup.enter="focusPassForm"
            v-model="credentials.email"
            required
            :rules="rules.email"
            :counter="50"
            maxlength="50"
            label="メールアドレス"
            :color="formColor"
            prepend-icon="mdi-account-circle"
        />
        <v-text-field
            @keyup.enter="login"
            @click:append="shownPassword = !shownPassword"
            v-model="credentials.password"
            ref="passForm"
            required
            :rules="rules.password"
            :counter="20"
            maxlength="20"
            label="パスワード"
            :color="formColor"
            prepend-icon="mdi-lock"
            :append-icon="shownPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="shownPassword ? 'text' : 'password'"
        />
      </v-form>
      <v-card-text v-if="isErr" class="card-text-err" :class="errMessageColor">
        メールアドレスまたはパスワードが違います。
      </v-card-text>
      <v-card-actions class="px-8">
        <v-col>
          <v-btn
              @click="login"
              :disabled="!valid"
              :color="btnColor"
              :class="btnFontColor"
              :width="$vuetify.breakpoint.xs ? '100' : '160'"
          >
            ログイン
          </v-btn>
        </v-col>
        <v-col style="text-align: right;">
          <v-btn
              color="blue"
              class="white--text"
              :width="$vuetify.breakpoint.xs ? '100' : '120'"
              @click="openedSignUpDialog=true"
          >
            新規登録
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
    <NaviDialogSignUp :openedSignUpDialog="openedSignUpDialog" @closeSignUpDialog="closeSignUpDialog"/>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import {mapMutations, mapState} from 'vuex';
import NaviDialogSignUp from '@/components/molecules/navi/NaviDialogSignUp.vue'


export default {
  name: "NaviDialogLogin",
  data() {
    return {
      /* フォーム入力値 */
      credentials: {
        email: '',
        password: ''
      },
      /* フォーム群入力状態管理 */
      valid: false,
      /* フォーム入力規制 */
      rules: {
        email: [
          v => !!v || 'メールアドレスは必須です',
        ],
        password: [
          v => !!v || 'パスワードは必須です',
          v => (v && v.length > 4) || 'パスワードは5文字以上でなければなりません'
        ]
      },
      /* パスワードフォーム表示状態管理 */
      shownPassword: false,
      /* フォーム入力値エラー状態管理 */
      isErr: false,
      /* Style関連 */
      formColor: 'green accent-4',
      btnColor: 'green accent-4',
      btnFontColor: 'white--text',
      errMessageColor: 'red--text',
      /* REST API 関連*/
      restApiBasePath: '',
      restApiAuthPath: '/api/auth/',
      /* サインアップダイアログ状態管理 */
      openedSignUpDialog: false
    }
  },
  components: {
    NaviDialogSignUp
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedLoginDialog: Boolean
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token','userId'])
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* Vuexのmutationを展開 */
    ...mapMutations([
      'setToken',
      'setUserId',
      'setLoginState'
    ]),
    /* パスワードフォームへ移動する処理 */
    focusPassForm() {
      this.$refs.passForm.focus();
    },
    /* ログインバリデーション処理 */
    login() {
      /* フォーム未入力の場合処理を行わずリターン */
      if (!this.valid) {
        return;
      }
      /* 全てのバリデーションチェック */
      if (this.$refs.form.validate()) {
        /* ログイン処理 */
        this.loginImplement();
      }
    },
    /* ログイン処理 */
    loginImplement: async function() {
      /* 認証処理を同期呼び出し */
      await this.authenticate();
      if (!this.userId) {
        this.isErr = true;
        return;
      }
      /* ログイン状態に変更(いらないかも) */
      this.setLoginState(true);
      /* 入力値の初期化 */
      this.$refs.form.reset();
      /* ダイアログのクローズ */
      const doneAuth = true;
      this.closeThisDialog(doneAuth);
    },
    /* Email,Passwordによる認証処理 */
    authenticate: async function () {
      /* バックエンド向けのデータ作成 */
      const requestBody = {
        email: this.credentials.email,
        password: this.credentials.password
      };
      /* バックエンドのAPI呼び出し */
      await axios.post(this.restApiBasePath + this.restApiAuthPath, requestBody).then(res => {
        /* 認証情報を保存 */
        this.storeAuthInfo(res.data.token);
      }).catch(e => {
        console.log(e.message);
      });
    },
    /* 認証情報を保存する処理 */
    storeAuthInfo(token) {
      /* Vuexにトークンを保存 */
      this.setToken(token);
      /* トークンからユーザーIDを取得しVuexに保存 */
      let payload = token.split('.')[1];
      let userId = JSON.parse(atob(payload)).user_id;
      this.setUserId(userId);
    },
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog(doneAuth=false) {
      this.$emit('doneAuth', doneAuth)
    },
    /* サインアップダイアログのクローズ */
    closeSignUpDialog: async function(signUpData) {
      /* サインアップ処理完了チェック */
      if (signUpData) {
        /* サインアップ情報の格納 */
        this.credentials.email = signUpData.email;
        this.credentials.password = signUpData.password;
        /* ログイン処理 */
        this.loginImplement();
      }
      this.openedSignUpDialog = false
    }
  }
}
</script>

<style scoped>
  /* タイトルフォント */
  .card-title {
    text-align: center;
    color: #00C853;
    font-weight: bold !important;
    font-family: 'Roboto', sans-serif !important;
    display: inherit;
  }

  /* エラーメッセージ位置調整 */
  .card-text-err {
    text-align: center;
  }

  /* ボタン内のアイコンサイズ調整 */
  .icon {
    font-size: 18px;
  }
</style>