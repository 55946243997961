<template>
  <v-dialog
      v-model="openedProfEditDialog"
      @click:outside="closeThisDialog"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="600">
    <v-card class="pa-10">
      <v-card-title class="headline" :class="$vuetify.breakpoint.xs ? 'pa-2' : 'ma-0 pa-0'">
        <p class="ma-0 pa-0 pl-2" :style="$vuetify.breakpoint.xs ? 'width: 100%; text-align: center !important;' : ''">
          <v-icon v-if="$vuetify.breakpoint.xs" @click="closeThisDialog" :style="$vuetify.breakpoint.xs ? 'position: absolute; top: 12px; left: 12px;' : ''">
            mdi-close-thick
          </v-icon>
          プロフィール編集
        </p>
        <v-spacer></v-spacer>
        <v-btn
            color="blue"
            class="ma-2 px-3"
            :class="btnFontColor"
            :small="$vuetify.breakpoint.xs"
            :style="$vuetify.breakpoint.xs ? 'margin-left: auto !important;' : ''"
            @click="openedGroupProfEditDialog = true"
        >
          <span v-if="editData.domain">企業情報編集</span>
          <span v-else>背景画像編集</span>
        </v-btn>
        <ProfileDialogGroupProfEdit
            :openedGroupProfEditDialog="openedGroupProfEditDialog"
            @closeGroupProfEditDialog="closeGroupProfEditDialog"
        />
      </v-card-title>
      <v-divider></v-divider>
      <v-row class="ma-0 pa-8" justify="center">
        <v-avatar
            class="profile ma-0 pa-0"
            color="grey"
            size=180
            max-height="100%"
            style="margin-left: -185px"
        >
          <v-img
              gradient="rgba(0,0,0,0.6), rgba(0,0,0,0.1)"
              :src="editData.icon"
          >
            <v-btn
                icon
                color="white"
                style="position:relative; top:72px; left:72px"
                @click="onClickIconUpBtn"
            >
              <v-icon large>mdi-plus-circle-outline</v-icon>
            </v-btn>
            <input
                ref="uploader"
                class="d-none"
                type="file"
                accept="image/*"
                @change="onIconChanged"
            >
            <ProfileDialogProfImgEdit
                :openedProfImgEditDialog="openedProfImgEditDialog"
                :iconImgSrc="iconImgSrc"
                @closeProfImgEditDialog="closeProfImgEditDialog"
            />
          </v-img>
        </v-avatar>
      </v-row>
      <v-row v-if="invalidSize"
             class="ma-0 pb-4 px-4 red--text"
             style="font-size: 14px;">
        ※ファイルサイズが超過しています(最大3MB)
      </v-row>
      <v-row :class="$vuetify.breakpoint.xs ? 'mx-4' : 'ma-0 pa-0'">
        <v-text-field
            label="名前"
            :color="formColor"
            :counter="30"
            :maxlength="30"
            v-model="editData.name"
        ></v-text-field>
      </v-row>
      <v-row :class="$vuetify.breakpoint.xs ? 'mx-4' : 'ma-0 pa-0'">
        <v-textarea
            label="自己紹介"
            :color="formColor"
            :counter="200"
            :maxlength="200"
            no-resize
            v-model="editData.description"
        ></v-textarea>
      </v-row>
      <v-card-actions>
        <v-col class="pa-0 pb-4" style="text-align: center;">
          <v-btn
              color="green accent-4"
              class="mt-6 px-16 py-4 white--text"
              @click="editProfile()"
          >
            保存
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import {mapMutations, mapState} from 'vuex';
import ProfileDialogProfImgEdit from "../../molecules/profile/ProfileDialogProfImgEdit";
import ProfileDialogGroupProfEdit from "../../molecules/profile/ProfileDialogGroupProfEdit";

export default {
  name: "ProfileDialogProfEdit",
  data() {
    return {
      /* フォーム入力値 */
      editData: {
        domain: '',
        icon: '',
        name: '',
        description: ''
      },
      /* 編集前のアイコン画像データ */
      iconImgSrc: null,
      /* 送信用のアイコン画像データ */
      sendIconSrc: null,
      /* アップロードファイルのサイズ制限の状態 */
      invalidSize: '',
      /* Style関連 */
      formColor: 'green accent-4',
      btnColor: 'green accent-4',
      btnFontColor: 'white--text',
      /* REST API 関連*/
      restApiBasePath: '',
      restApiUserPath: '/api/user/',
      restApiUserImgPath: '/api/user/img/',
      /* 子ダイアログの状態管理 */
      openedGroupProfEditDialog: false,
      openedProfImgEditDialog: false,
    }
  },
  components: {
    ProfileDialogProfImgEdit,
    ProfileDialogGroupProfEdit
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedProfEditDialog: Boolean
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token', 'userId', 'globalNaviReload', 'globalMainReload'])
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  mounted() {
    /* 初期値の格納 */
    this.getUserInfo();
  },
  methods: {
    /* Vuex展開 */
    ...mapMutations([ 'setGlobalNaviReload', 'setGlobalMainReload' ]),

    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog() {
      this.$emit('closeProfEditDialog', false)
    },
    /* 画像編集ダイアログのクローズ */
    closeProfImgEditDialog(clopImg = null) {
      if (clopImg) {
        this.editData.icon = clopImg.urlData;
        this.sendIconSrc = clopImg.blobData;
      }
      this.openedProfImgEditDialog = false;
    },
    closeGroupProfEditDialog(doneGroupEdit) {
      if (doneGroupEdit) {
        this.setGlobalMainReload(!this.globalMainReload);
        this.setGlobalNaviReload({'reload':!this.globalNaviReload, 'target':'getUserInfo'});
        this.$emit('closeProfEditDialog', true)
      }
      this.openedGroupProfEditDialog = false;
    },
    /* アップロードアイコン押下時にファイルinput発火 */
    onClickIconUpBtn() {
      this.$refs.uploader.click();
    },
    /* アイコン画像アップロード */
    onIconChanged(e) {
      /* 画像取得処理 */
      let uploadFile = e.target.files[0];
      /* サイズチェック */
      const maxSize = 1024 * 1024 * 3;
      this.invalidSize = uploadFile.size > maxSize;
      if(!this.invalidSize) {
        /* URLに変換 */
        this.iconImgSrc = URL.createObjectURL(uploadFile);
        /* 画像編集ダイアログオープン */
        this.openedProfImgEditDialog = true;
      }
    },
    /* ユーザ情報の取得 */
    getUserInfo() {
      /* バックエンドのAPI呼び出し */
      axios.get(this.restApiBasePath + this.restApiUserPath + this.userId + '/').then(res => {
        /* プロフィール情報の格納 */
        this.editData.domain = res.data.group__domain;
        this.editData.name = res.data.name;
        this.editData.description = res.data.description;
        if (res.data.img !== null) {
          this.editData.icon = process.env.VUE_APP_URL + '/media/' + res.data.img + '?' + Math.random();
        } else {
          this.editData.icon = ""
        }
      }).catch(e => {
        console.log(e.message);
      });
    },
    /* プロフィール編集処理 */
    editProfile: async function () {
      await this.sendProfInfo();
      await this.sendProfImg();
      this.getUserInfo();
      this.setGlobalMainReload(!this.globalMainReload);
      this.setGlobalNaviReload({'reload':!this.globalNaviReload, 'target':'getUserInfo'});
      this.$emit('closeProfEditDialog', true)
    },
    /* プロフィール情報送信処理 */
    sendProfInfo: async function () {
      /* バックエンド向けのデータ作成 */
      const reqBody = {
        'name': this.editData.name,
        'description': this.editData.description,
      };
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      /* バックエンドのAPI呼び出し */
      await axios.put(this.restApiBasePath + this.restApiUserPath + this.userId + '/', reqBody, reqHeader)
          .catch(e => {
            console.log(e.message);
          });
    },
    /* プロフィール画像情報送信処理 */
    sendProfImg: async function () {
      /* バックエンド向けのデータ作成 */
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      /* バックエンドのAPI呼び出し */
      await axios.put(this.restApiBasePath + this.restApiUserImgPath + this.userId + '/', this.sendIconSrc, reqHeader)
          .catch(e => {
            console.log(e.message);
          });
    },

  }
}
</script>

<style scoped>
</style>