<template>
  <div class="maindivider">
    <v-row no-gutters class="ma-0 pa-0">
      <v-col
        cols="12"
        sm="12"
        class="pa-0 ma-0"
      >
        <v-sheet class="pa-0 ma-0" tile color="grey lighten-3">
          <p class="ma-0 pa-1"></p>
        </v-sheet>
      </v-col>
    </v-row>
    <v-divider/>
  </div>
</template>

<script>
    export default {
        name: "MainDivider"
    }
</script>

<style scoped>

</style>