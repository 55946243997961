<template>
  <v-navigation-drawer
      app
      clipped
      right
      width="420"
      color="grey lighten-3"
  >
    <v-container>
      <v-img src="@/assets/default_ad.png"
        class="mb-4"
      >
      </v-img>
      <v-card
          color="white"
      >
        <v-list disabled>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                お知らせ
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item-group>
            <v-list-item>
              <v-list-item-content class="py-0">
                <v-list-item-subtitle class="ma-0 pa-0">
                  <v-row class="ma-0">
                    <v-col cols="3">2021/5/25</v-col>
                    <v-col cols="9">正式サービス提供開始</v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-container>
    <v-sheet
        width="100%"
        color="grey lighten-3"
    >
      <v-container>
        <v-row
            no-gutters
            class="caption mb-1"
        >
          <v-col
              cols="1"
          >
          </v-col>
          <v-col
              cols="3"
          >
            <a class="green--text" @click="openedTermsDialog = true">利用規約</a>
          </v-col>
          <v-col
              cols="5"
          >
            <a class="green--text" @click="openedPrivacyDialog = true">プライバシーポリシー</a>
          </v-col>
          <v-col
              cols="3"
          >
            <a href="https://amayz.co.jp" class="green--text" style="text-decoration: none;">運営会社情報</a>
          </v-col>
        </v-row>
        <v-row
            no-gutters
            class="caption"

        >
          <v-col
              cols="1"
          >
          </v-col>
          <v-col
              cols="8"
          >
            <a href="https://amayz.co.jp/contact/" class="green--text" style="text-decoration: none;">お問い合わせ</a>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <NaviDialogTerms :openedTermsDialog="openedTermsDialog" @closeTermsDialog="closeTermsDialog"/>
    <NaviDialogPrivacy :openedPrivacyDialog="openedPrivacyDialog" @closePrivacyDialog="closePrivacyDialog"/>
    <NaviDialogAdvertising
        :openedAdvertisingDialog="openedAdvertisingDialog"
        @closeAdvertisingDialog="closeAdvertisingDialog"
    />
  </v-navigation-drawer>
</template>

<script>
import NaviDialogTerms from "@/components/molecules/navi/NaviDialogTerms";
import NaviDialogPrivacy from "@/components/molecules/navi/NaviDialogPrivacy";
import NaviDialogAdvertising from "@/components/molecules/navi/NaviDialogAdvertising";

export default {
  name: "NaviRight",
  data() {
    return {
      /* 子コンポーネント状態管理 */
      openedTermsDialog: false,
      openedPrivacyDialog: false,
      openedAdvertisingDialog: false
    }
  },
  components: {
    NaviDialogTerms,
    NaviDialogPrivacy,
    NaviDialogAdvertising
  },
  methods: {
    /* 利用規約ダイアログのクローズ処理 */
    closeTermsDialog() {
      this.openedTermsDialog = false;
    },
    /* プライバシーポリシーダイアログのクローズ処理 */
    closePrivacyDialog() {
      this.openedPrivacyDialog = false;
    },
    /* 広告情報ダイアログのクローズ処理 */
    closeAdvertisingDialog() {
      this.openedAdvertisingDialog = false;
    },
  }
}
</script>

<style scoped>

</style>