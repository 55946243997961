<template>
  <div class="message">
    <v-app>
      <MessagePage/>
    </v-app>
  </div>
</template>

<script>
import MessagePage from '@/components/pages/MessagePage.vue'

export default {
  name: 'Message',
  components: {
    MessagePage,
  }
}
</script>