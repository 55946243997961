import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '../views/Index.vue'
import Explore from '../views/Explore.vue'
import Bp from '../views/Bp.vue'
import Message from '../views/Message.vue'
import Profile from '../views/Profile.vue'

import Store from '../store/index.js'

import Meta from 'vue-meta'

Vue.use(Meta);
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Index,
        children: [
            {
                path: '/',
                name: 'Explore',
                component: Explore,
                meta: {title: 'SE-Hub'}
            },
            {
                path: '/bp',
                name: 'Bp',
                component: Bp,
                meta: {title: 'SE-Hub', requiresAuth: true}
            },
            {
                path: '/message',
                name: 'Message',
                component: Message,
                meta: {title: 'SE-Hub', requiresAuth: true}
            },
            {
                path: '/profile/:id',
                name: 'Profile',
                component: Profile,
                // props: route => ({ id: String(route.params.id) }),
                meta: {title: 'SE-Hub'}
            }
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !Store.state.token) {
        next({path: '/'})
    }else{
     next()
    }
})


export default router
