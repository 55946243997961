<template>
  <v-dialog
      v-model="openedAlarmConfDialog"
      @click:outside="closeThisDialog"
      max-width="420"
  >
    <v-card :class="$vuetify.breakpoint.xs ? 'px-0 py-6' : 'px-10 py-6'">
      <v-card-text class="px-6 pt-10 pb-6" style="font-weight: bold; text-align: center;">
        このメッセージを通報しますか？
      </v-card-text>
      <v-card-actions style="text-align: center;">
        <v-col>
          <v-btn
              color="red"
              class="white--text"
              @click="alarmMessage"
          >
            通報する
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
              color="grey"
              class="white--text"
              @click.stop="closeThisDialog"
          >
            キャンセル
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: "MessageAlarmConfDialog",
  data() {
    return {
      /* Style関連 */
      formColor: 'green accent-4',
      btnColor: 'green accent-4',
      btnFontColor: 'white--text',
      /* REST API 関連*/
      restApiBasePath: '',
      restApiMessageAlarmPath: '/api/message/alarm/',
    }
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedAlarmConfDialog: Boolean,
    messageId: String
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token','userId'])
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog() {
      this.$emit('closeAlarmConfDialog', false)
    },
    /* 通報処理 */
    alarmMessage () {
      /* バックエンドのAPI呼び出し */
      axios.put(this.restApiBasePath + this.restApiMessageAlarmPath + this.messageId + '/')
      .catch(e => {
        console.log(e.message);
      });
      this.closeThisDialog();
    }
  }
}
</script>

<style scoped>
</style>