<template>
  <v-dialog
      v-model="openedSetHashDialog"
      @click:outside="closeDialog()"
      :content-class="$vuetify.breakpoint.xs ? 'mx-0' : ''"
      max-width="400"
  >
    <v-card class="pa-10">
      <v-card-title class="headline" style="text-align: center; display: inherit;">
        確認コード入力
      </v-card-title>
      <v-card-text class="mt-6">
        ご入力いただいたメールアドレス宛に、<br v-if="$vuetify.breakpoint.smAndUp">４桁の確認コードを記載したメールを送信しています。<br>
        (迷惑メールボックスに入っていないかご確認下さい。)
      </v-card-text>
      <v-form ref="form" @submit.prevent="sendSignUpInfo" :class="$vuetify.breakpoint.xs ? 'px-2 pt-2 pb-6' : 'px-10 pt-2 pb-6'" style="text-align: center;">
        <v-text-field
            label="input code"
            :color="formColor"
            class="centered-input"
            :counter="4"
            :length="4"
            :maxlength="4"
            v-model="hashKey"
        ></v-text-field>
      </v-form>
      <v-card-text v-if="errorMessage" class="red--text" style="text-align: center;">
        {{ this.errorMessage }}
      </v-card-text>
      <v-card-actions>
        <v-col style="text-align: center;">
          <v-btn
              :disabled="!alreadySetHash"
              :class="btnFontColor"
              width="80%"
              :color="btnColor"
              @click="sendSignUpInfo"
          >
            確認
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import {mapMutations, mapState} from 'vuex';

export default {
  name: "NaviDialogSetHash",
  data() {
    return {
      /* フォーム入力値 */
      hashKey: '',
      /* エラーメッセージ */
      errorMessage:'',
      /* Style関連 */
      formColor: 'green accent-4',
      btnColor: 'green accent-4',
      btnFontColor: 'white--text',
      errMessageColor: 'red--text',
      /* REST API 関連*/
      restApiBasePath: '',
      restApiUserPath: '/api/user/'
    }
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedSetHashDialog: Boolean,
    /* 親コンポーネントへからフォーム入力情報受け取り */
    credentials: Object
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token', 'userId']),
    /* ハッシュキーの入力状態 */
    alreadySetHash: function () {
      return (this.hashKey.length === 4)
    }
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* Vuexのmutationを展開 */
    ...mapMutations([
      'setToken',
      'setUserId',
      'setLoginState'
    ]),
    sendSignUpInfo() {
      if (!this.alreadySetHash) {
        this.errorMessage = '確認コードを入力してください';
        return
      }
      const reqBody = {
        'name': this.credentials.name,
        'email': this.credentials.email,
        'password': this.credentials.password,
        'hash_cd': this.hashKey,
      };
      axios.post(this.restApiBasePath + this.restApiUserPath, reqBody)
      .then(res => {
        if (res.status.toString() !== '200') {
          this.errorMessage = res.data.errorMessage
        } else {
          this.errorMessage = '';
          this.closeDialog(true)
        }
      })
      .catch( e => {
        this.errorMessage = e.response.data.errorMessage
      });
    },
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeDialog(doneSignUp = false) {
      this.hashKey = '';
      this.errorMessage = '';
      this.$emit('closeSetHashDialog', doneSignUp);
    },
  }
}
</script>

<style scoped>
/* タイトルフォント */
.card-title {
  text-align: center;
  color: #00C853;
  font-family: 'Franklin Gothic Medium' !important;
  display: inherit;
}

/* エラーメッセージ位置調整 */
.card-text-err {
  text-align: center;
}

/* ボタン内のアイコンサイズ調整 */
.icon {
  font-size: 18px;
}

/* 確認コード入力フォームの文字を中央寄せ */
>>> .centered-input input {
  text-align: center;
}
</style>