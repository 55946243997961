<template>
  <div>
    <v-row v-if="messageList" no-gutters class="ma-0 pa-0">
      <v-list flat width="100%" class="ma-0 pa-0">
        <v-list-item-group
            v-for="(messageItm,index) in messageList"
            :key="index"
        >
          <v-list-item
              v-if="userId!==messageItm.user1__id"
              :ripple="false"
              @click="openMessageDialog(messageItm)"
          >
            <v-list-item-avatar @click="toProfile(messageItm.user1__id)">
              <v-img v-if="messageItm.user1__img" :src="restApiBasePath + '/media/' + messageItm.user1__img"/>
              <v-img v-else src="@/assets/default_avatar.jpeg"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                  style="font-weight: bold; font-size: 14px; opacity: 0.75;"
              >
                <p class="text-ellipsis ma-0 pa-0"
                   :style="$vuetify.breakpoint.xs ? 'width: calc(100vw - 220px);' : 'width: calc(100vw - 580px);'">
                  <span v-if="messageItm.user1__group__name !== null"
                        style="color: darkslateblue;">
                    {{ messageItm.user1__group__name }}<br/>
                  </span>
                  {{ messageItm.user1__name }}
                </p>
                <span style="position: absolute; top: 12px; right: 32px;">
                  {{ parseTime(messageItm.update_datetime) }}
                </span>
                <v-menu v-if="(messageItm.user1__id !== userId ) && (userId)" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-icon
                        style="position: absolute; right: 12px;"
                        :style="$vuetify.breakpoint.xs ? 'top: 11px;' : 'top: 11px;'"
                        small
                        v-on="on"
                    >
                      mdi-dots-vertical
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item :ripple="false" @click.stop="openAlarmConfDialog(content)">
                      <v-list-item-title
                          style="line-height: 24px; font-size: 13px; font-weight: bold; opacity: 0.6;"
                      >
                        <v-icon style="opacity: 0.75;">mdi-account-off</v-icon>
                        この投稿を通報する
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item :ripple="false" @click.stop="openBlockConfDialog(content)">
                      <v-list-item-title
                          style="line-height: 24px; font-size: 13px; font-weight: bold; opacity: 0.6;"
                      >
                        <v-icon style="opacity: 0.75;">mdi-alarm-light-off</v-icon>
                        このユーザーをブロックする
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-title>
              <v-list-item-title class="ma-0 py-2 pr-4" style="font-weight: bold;"
                                 :style="$vuetify.breakpoint.xs ? 'font-size: 14px;' : ''">
                {{ messageItm.title }}
              </v-list-item-title>
              <span v-if="(userId !== messageItm.update_user) && (messageItm.no_read_count>0)">
                <span style="position: absolute; bottom: 22px;"
                      :style="messageItm.no_read_count > 10 ? 'right: -10px;' : 'right: -14px;'">
                  <v-tab>
                    <v-badge
                        color="red"
                        :content="messageItm.no_read_count"
                    ></v-badge>
                  </v-tab>
                </span>
              </span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              v-else
              :ripple="false"
              @click="openMessageDialog(messageItm)"
          >
            <v-list-item-avatar @click="toProfile(messageItm.user2__id)">
              <v-img v-if="messageItm.user2__img" :src="restApiBasePath + '/media/' + messageItm.user2__img"/>
              <v-img v-else src="@/assets/default_avatar.jpeg"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                  style="font-weight: bold; font-size: 14px; opacity: 0.75;"
              >
                <p class="text-ellipsis ma-0 pa-0"
                   :style="$vuetify.breakpoint.xs ? 'width: calc(100vw - 220px);' : 'width: calc(100vw - 580px);'">
                  <span v-if="messageItm.user2__group__name" style="color: darkslateblue;">
                    {{ messageItm.user2__group__name }}<br/>
                  </span>
                  {{ messageItm.user2__name }}
                </p>
                <span style="position: absolute; top: 12px; right: 32px;">
                  {{ parseTime(messageItm.update_datetime) }}
                </span>
                <v-icon
                    style="position: absolute; right: 12px;"
                    :style="$vuetify.breakpoint.xs ? 'top: 11px;' : 'top: 11px;'"
                    small
                >
                  mdi-dots-vertical
                </v-icon>
              </v-list-item-title>
              <v-list-item-title class="ma-0 py-2 pr-4" style="font-weight: bold;"
                                 :style="$vuetify.breakpoint.xs ? 'font-size: 14px;' : ''">
                {{ messageItm.title }}
              </v-list-item-title>
              <span v-if="(userId !== messageItm.update_user) && (messageItm.no_read_count>0)">
                <span style="position: absolute; bottom: 22px; right: -14px;">
                  <v-tab>
                    <v-badge
                        color="red"
                        :content="messageItm.no_read_count"
                    ></v-badge>
                  </v-tab>
                </span>
              </span>
              <v-menu v-if="(messageItm.user2__id !== userId ) && (userId)" offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon
                      style="position: absolute; right: 12px;"
                      :style="$vuetify.breakpoint.xs ? 'top: 11px;' : 'top: 11px;'"
                      small
                      v-on="on"
                  >
                    mdi-dots-vertical
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item :ripple="false" @click.stop="openAlarmConfDialog(content)">
                    <v-list-item-title
                        style="line-height: 24px; font-size: 13px; font-weight: bold; opacity: 0.6;"
                    >
                      <v-icon style="opacity: 0.75;">mdi-account-off</v-icon>
                      この投稿を通報する
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item :ripple="false" @click.stop="openBlockConfDialog(content)">
                    <v-list-item-title
                        style="line-height: 24px; font-size: 13px; font-weight: bold; opacity: 0.6;"
                    >
                      <v-icon style="opacity: 0.75;">mdi-alarm-light-off</v-icon>
                      このユーザーをブロックする
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-list-item-group>
        <InfiniteLoading :forceUseInfiniteWrapper="true" :identifier="infiniteId" @infinite="getMessageList">
          <span slot="no-more"></span>
          <span slot="no-results">
            <span style="display: inline-block; margin-top: 32px; margin-bottom: 32px; font-size: 14px; opacity: 0.8;">メッセージはありません</span>
          </span>
        </InfiniteLoading>
      </v-list>
    </v-row>
    <MessageDialog
        :openedMessageDialog="openedMessageDialog"
        :selectedMessage="selectedMessage"
        @closeMessageDialog="closeMessageDialog"
    />
    <MessageAlarmConfDialog
        :openedAlarmConfDialog="openedAlarmConfDialog"
        :messageId="messageId"
        @closeAlarmConfDialog="closeAlarmConfDialog"
    />
    <MessageBlockConfDialog
        :openedBlockConfDialog="openedBlockConfDialog"
        :otherId="otherId"
        @closeBlockConfDialog="closeBlockConfDialog"
    />
  </div>
</template>

<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
import {mapMutations, mapState} from 'vuex';
import MessageDialog from "../../molecules/message/MessageDialog";
import MessageAlarmConfDialog from "../../molecules/message/MessageAlarmConfDialog";
import MessageBlockConfDialog from "../../molecules/message/MessageBlockConfDialog";


export default {
  name: "MainMessageList",
  data() {
    return {
      /* メッセージリスト */
      messageList: [],
      /* メッセージリストの取得件数 */
      count: 1,
      /* リスト再取得のためのリセット変数 */
      infiniteId: +new Date(),
      /* 選択メッセージ情報 */
      selectedMessage: null,
      /* ブロック&アラーム用変数 */
      otherId: '',
      messageId: '',
      /* 定期実行処理管理用変数 */
      intervalId: null,
      /* REST API 関連*/
      restApiBasePath: '',
      restApiMessageListPath: '/api/message/list/',
      /* 子ダイアログの状態管理 */
      openedMessageDialog: false,
      openedAlarmConfDialog: false,
      openedBlockConfDialog: false
    }
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token', 'userId', 'globalNaviReload'])
  },
  components: {
    MessageAlarmConfDialog,
    MessageBlockConfDialog,
    MessageDialog,
    InfiniteLoading
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  mounted: function () {
    /* 定期的にメッセージリスト取得 */
    this.intervalId = window.setInterval(() => {
      this.reload();
    }, 60000);
  },
  destroyed() {
    /* 定期処理の解除 */
    window.clearInterval(this.intervalId);
  },
  methods: {
    /* Vuex展開 */
    ...mapMutations([ 'setGlobalNaviReload' ]),
    /* メッセージダイアログのクローズ処理 */
    closeMessageDialog() {
      this.reload();
      this.setGlobalNaviReload({ 'reload' :!this.globalNaviReload, 'target':'getNoReadCount'});
      this.openedMessageDialog = false;
    },
    /* 通報確認ダイアログのクローズ処理 */
    closeAlarmConfDialog() {
      this.openedAlarmConfDialog = false;
    },
    /* ブロック確認ダイアログのクローズ処理 */
    closeBlockConfDialog() {
      this.openedBlockConfDialog = false;
    },
    /* メッセージダイアログのオープン処理 */
    openMessageDialog(message) {
      this.selectedMessage = message;
      this.openedMessageDialog = true;
    },
    /* 通報確認ダイアログのオープン処理 */
    openAlarmConfDialog(messageId) {
      this.messageId = messageId;
      this.openedAlarmConfDialog = true;
    },
    /* ブロック確認ダイアログのオープン処理 */
    openBlockConfDialog(otherId) {
      this.otherId = otherId;
      this.openedBlockConfDialog = true;
    },
    /* メッセージリストの取得処理 */
    getMessageList($state) {
      /* バックエンド向けのデータ作成 */
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      const reqBody = {
        'count': this.count
      };
      /* バックエンドのAPI呼び出し */
      axios.post(this.restApiBasePath + this.restApiMessageListPath
          + this.userId + '/', reqBody, reqHeader).then(res => {
        if (res.status.toString() === '200') {
          this.count += 1;
          /* 取得したリストを格納 */
          this.messageList.push(...res.data);
          $state.loaded();
        } else if (res.status.toString() === '204') {
          $state.complete();
        }
      }).catch(e => {
        console.log(e.message);
      });
    },
    /* 時刻変換処理 */
    parseTime(insertDatetime) {
      let ts = Date.parse(insertDatetime);
      const dt = new Date(ts);
      const year = dt.getFullYear();
      const month = dt.getMonth() + 1;
      const days = dt.getDate();
      const hours = this.zeroPadding(dt.getHours(), 2);
      const minutes = this.zeroPadding(dt.getMinutes(), 2);
      return year + "/" + month + "/" + days + " " + hours + ":" + minutes
    },
    zeroPadding(num, length) {
      return ('0000000000' + num).slice(-length);
    },
    /* リスト情報の再取得 */
    reload() {
      this.messageList = [];
      this.count = 1;
      this.infiniteId += 1;
    },
    /* プロフィール遷移処理 */
    toProfile(userId) {
      this.$router.push('/profile/' + userId).catch(err => {
        console.log(err);
      });
    },
  }
}
</script>

<style scoped>

</style>