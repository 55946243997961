<template>
  <div>
    <v-main>
      <v-container
        class="ma-0 pa-0"
      >
        <MainTitle :mainTitleName="mainTitleName" v-if="$vuetify.breakpoint.smAndUp"/>
        <MainMessageList/>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import MainTitle from '@/components/organisms/main/MainTitle.vue'
import MainMessageList from '@/components/organisms/main/MainMessageList.vue'

export default {
  metaInfo: {
    title: 'SE-Hub',
    titleTemplate: 'メッセージ | %s',
    meta: [
      { name: "robots", content: "noindex" },
    ]
  },
  name: "MessagePage",
  components: {
    MainTitle,
    MainMessageList
  },
  data () {
    return {
      mainTitleName: "メッセージ",
    }
  }
}
</script>

<style scoped>

</style>