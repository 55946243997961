import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const initialState = {
        loginState: false,
        userName: "",
        groupName: '',
        userId: "",
        userKey: '',
        userImg: '',
        token: "",
        profileUserId: "",
        profileBp: "",
        contentsList:"",
        /* 検索キーワード */
        globalSearchString: '',
        /* NaviからMainへの更新フラグ */
        globalMainReload: false,
        /* MainからNaviへの更新フラグ */
        globalNaviReload: false,
        /* MainからNaviへの更新時のターゲット */
        globalNaviReloadTarget: ''

};

export default new Vuex.Store({
    state: initialState,
    mutations: {
      /* 検索キーワードのセッター */
      setGlobalSearchString: function (state, val) {
        state.globalSearchString = val;
      },
      setLoginState: function (state, val) {
          state.loginState = val
      },
      setUserName: function (state, val) {
          state.userName = val
      },
      setGroupName: function (state, val) {
          state.groupName = val;
      },
      setUserId: function (state, val) {
          state.userId = val
      },
      setUserKey: function (state, val) {
          state.userKey = val;
      },
      setUserImg: function (state, val) {
          state.userImg = val;
      },
      setToken: function (state, val) {
          state.token = val
      },
      setProfileUserId: function (state, val) {
          state.profileUserId = val
      },
      setProfileBp: function (state, val) {
          state.profileBp = val
      },
      setContentsList: function (state, val) {
          state.contentsList = val
      },
      /* NaviからMainへの更新フラグ */
      setGlobalMainReload: function (state, val) {
          state.globalMainReload = val
      },
      /* MainからNaviへの更新フラグ */
      setGlobalNaviReload: function (state, {reload, target=''} ) {
          state.globalNaviReloadTarget = target;
          state.globalNaviReload = reload;
      }
    },
    actions: {},
    modules: {},
    plugins: [createPersistedState()]
})
