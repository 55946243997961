<template>
  <div class="explorepage">
    <v-main>
      <v-container class="ma-0 pa-0">
        <ExploreForm @enterSearchForm="getSearchVal" />
        <ContentList :searchVal="searchVal" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import ExploreForm from '@/components/molecules/explore/ExploreForm.vue'
import ContentList from '@/components/molecules/common/ContentList.vue'

export default {
  metaInfo: {
    title: 'SE-Hub',
    titleTemplate: '%s',
    meta: [
      { vmid: 'description', name: 'description',
        content: '今よりもっと効率よく「エンジニアを集める」「案件情報を探す」 を実現するサービス。 （機能の制限や有料プラン等は一切ありません。）'
      },
    ]
  },
  name: "ExplorePage",
  components: {
    ExploreForm,
    ContentList
  },
  data () {
    return {
      searchVal: {}
    }
  },
  methods: {
    getSearchVal(val) {
      this.searchVal = val;
    }
  }
}
</script>

<style scoped>

</style>