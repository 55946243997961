var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-tabs',{attrs:{"fixed-tabs":"","color":_vm.tabColor},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_vm._l((_vm.tabLabels),function(tabLabel,index){return _c('v-tab',{key:index,style:((_vm.$vuetify.breakpoint.xs) ? 'font-size: small; padding: 0;' : ''),attrs:{"ripple":false}},[_vm._v(" "+_vm._s(tabLabel)+" ")])}),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-divider'),_c('v-tab-item',{attrs:{"transition":false,"reverse-transition":false}},[_c('BpUserList',{attrs:{"searchString":_vm.searchString,"selectedTab":_vm.selectedTab.toString(),"tabType":"0","reload":_vm.reload},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var otherId = ref.otherId;
return [_c('BpRequestBtn',{attrs:{"otherId":otherId},on:{"enterBpRequestBtn":_vm.reacquireUserList}})]}}])})],1),_c('v-tab-item',{attrs:{"transition":false,"reverse-transition":false}},[_c('BpUserList',{attrs:{"searchString":_vm.searchString,"selectedTab":_vm.selectedTab.toString(),"tabType":"1","reload":_vm.reload},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var otherId = ref.otherId;
return [_c('BpCancellationBtn',{attrs:{"otherId":otherId},on:{"enterBpCancelBtn":_vm.reacquireUserList}})]}}])})],1),_c('v-tab-item',{attrs:{"transition":false,"reverse-transition":false}},[_c('BpUserList',{attrs:{"searchString":_vm.searchString,"selectedTab":_vm.selectedTab.toString(),"tabType":"2","reload":_vm.reload},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var otherId = ref.otherId;
return [_c('BpRequestBtn',{attrs:{"otherId":otherId},on:{"enterBpRequestBtn":_vm.reacquireUserList}})]}}])})],1),_c('v-tab-item',{attrs:{"transition":false,"reverse-transition":false}},[_c('BpUserList',{attrs:{"searchString":_vm.searchString,"selectedTab":_vm.selectedTab.toString(),"tabType":"3","reload":_vm.reload},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var otherId = ref.otherId;
return [_c('BpDeletionBtn',{attrs:{"otherId":otherId},on:{"enterBpRequestBtn":_vm.reacquireUserList}})]}}])})],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }