<template>
  <div>
    <v-row
        no-gutters
        class="ma-2 mb-0 pa-1 px-4 pb-0"
        v-if="$vuetify.breakpoint.smAndUp"
    >
      <v-col>
        <v-text-field
            label="キーワード検索"
            color="green accent-4"
            prepend-inner-icon="mdi-magnify"
            v-model="searchString"
            clearable
            @keyup.enter="sendSearchString()"
            @click:clear="clearSearchString()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row
        no-gutters
        class="mb-3 px-8 py-0"
        :style="$vuetify.breakpoint.xs ? 'margin-top: 12px;' : ''"
    >
      <v-col cols="8" sm="8" md="8" lg="8" xl="8">
        <v-switch
            v-if="userId"
            v-model="bpFilter"
            color="green accent-4"
            :dense="$vuetify.breakpoint.xs"
            inset
            :class="$vuetify.breakpoint.xs ? 'small-label' : ''"
            :label="'BPの投稿のみ表示'"
        />
      </v-col>
      <v-col
          cols="4"
          sm="4"
          md="4"
          lg="4"
          xl="4"
          :style="$vuetify.breakpoint.xs ? 'text-align: right;' : 'text-align: right;'"
      >
        <v-btn
            v-if="!filterArea"
            color="grey lighten-2"
            class="grey--text"
            :style="$vuetify.breakpoint.xs ? 'margin-top: 18px; margin-bottom: 18px;' : 'margin-top: 10px;'"
            depressed
            rounded
            :small="$vuetify.breakpoint.xs"
            :ripple="false"
            @click="filterArea = true"
        >
          <v-icon
              class="grey--text"
              :small="$vuetify.breakpoint.xs"
          >
            mdi-select-search
          </v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">フィルター</span>
        </v-btn>
        <v-btn
            v-if="filterArea"
            color="green accent-4"
            class="white--text"
            :style="$vuetify.breakpoint.xs ? 'margin-top: 18px; margin-bottom: 18px;' : 'margin-top: 10px;'"
            depressed
            rounded
            :ripple="false"
            :small="$vuetify.breakpoint.xs"
            @click="filterArea = false"
        >
          <v-icon
              color="white"
              :small="$vuetify.breakpoint.xs"
          >
            mdi-select-search
          </v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">フィルター</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider v-if="filterArea"/>
    <v-row
        v-if="filterArea"
        no-gutters
        class="pt-1 px-8 py-0"
    >
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <v-switch
            v-model="typeFilter"
            :class="$vuetify.breakpoint.xs ? 'small-label mt-4 mb-1' : ''"
            color="red"
            inset
            :dense="$vuetify.breakpoint.xs"
            value="1"
            :label="'案件情報'"
        />
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <v-switch
            v-model="typeFilter"
            :class="$vuetify.breakpoint.xs ? 'small-label mt-4 mb-1' : ''"
            color="blue"
            inset
            :dense="$vuetify.breakpoint.xs"
            value="2"
            :label="'人材情報'"
        />
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <v-switch
            v-model="typeFilter"
            :class="$vuetify.breakpoint.xs ? 'small-label my-1' : ''"
            color="orange"
            inset
            :dense="$vuetify.breakpoint.xs"
            value="3"
            :label="'交流会'"
        />
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <v-switch
            v-model="typeFilter"
            :class="$vuetify.breakpoint.xs ? 'small-label my-1' : ''"
            color="green"
            inset
            :dense="$vuetify.breakpoint.xs"
            value="5"
            :label="'その他'"
        />
      </v-col>
    </v-row>
    <v-divider/>
  </div>
</template>
<script>
import {mapState} from 'vuex';
// import axios from 'axios';


export default {
  name: "ExploreForm",
  components: {},
  data() {
    return {
      /* 検索文字列 */
      searchString: "",
      /* 検索フィルター */
      bpFilter: false,
      typeFilter: ['1','2','3','5'],
      /* フィルターエリア表示の状態管理 */
      filterArea: true,
    }
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['userId'])
  },
  watch: {
    /* BPフィルターの変更を感知し検索条件送信 */
    'bpFilter': function () {
      this.sendSearchString();
    },
    /* 検索種別フィルターの変更を感知し検索条件送信 */
    'typeFilter': function () {
      this.sendSearchString();
    }
  },
  mounted() {
    if (this.$vuetify.breakpoint.name !== 'xs') {
      this.getQuery();
    }
  },
  methods: {
    /* クエリの取得 */
    getQuery(){
      if(Object.keys(this.$route.query).length) {
        this.searchString = 'ID:' + this.$route.query.ID;
        this.sendSearchString();
      }
    },
    /* 検索文字列を親コンポーネントに送信する処理 */
    sendSearchString() {
      let searchVal = {
        searchString: this.searchString,
        bpFilter: this.bpFilter,
        typeFilter: this.typeFilter
      };
      this.$emit('enterSearchForm', searchVal);
    },
    /* クリアアイコン押下時の検索文字列空での再検索 */
    clearSearchString() {
      this.searchString = null;
      this.sendSearchString();
    }
  }
}
</script>

<style scoped></style>

<style>
.v-input--switch.small-label .v-label {
  font-size: 14px;
}
</style>