<template>
  <v-dialog
      v-model="openedDelConformDialog"
      @click:outside="closeThisDialog()"
      max-width="400"
  >
    <v-card :class="$vuetify.breakpoint.xs ? 'px-0 py-6' : 'px-10 py-6'">
      <v-card-text class="px-6 pt-10 pb-6" style="font-weight: bold; text-align: center;">
        本当に削除しますか？
      </v-card-text>
      <v-card-actions style="text-align: center;">
        <v-col>
          <v-btn
              color="red"
              class="white--text"
              @click="deleteContent"
          >
            削除する
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
              color="grey"
              class="white--text"
              @click="closeThisDialog()"
          >
            キャンセル
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: "DelConformDialog",
  data() {
    return {
      /* REST API 関連*/
      restApiBasePath: '',
      restApiDisclosurePath: '/api/disclosure/'
    }
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedDelConformDialog: Boolean,
    disclosureId: String
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token','userId'])
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog(deleted = false) {
      this.$emit('closeDelConformDialog', deleted)
    },
    /* 案件投稿の削除 */
    deleteContent() {
      /* バックエンド向けのデータ作成 */
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      /* バックエンドのAPI呼び出し */
      axios.delete(this.restApiBasePath + this.restApiDisclosurePath + this.disclosureId + '/', reqHeader).then(res => {
        if (res.status.toString() === '200') {
          /* 削除ができたらダイアログを閉じる */
          const deleted = true;
          this.closeThisDialog(deleted)
        }
      }).catch(e => {
        console.log(e.message);
      });
    }
  }
}
</script>

<style scoped>
</style>