<template>
  <v-dialog
      v-model="openedProfImgEditDialog"
      @click:outside="closeThisDialog"
      max-width="600"
  >
    <v-card class="pa-10">
      <v-card-title class="headline ma-0 pa-0">
        <p class="ma-0 pa-0 pl-2">画像編集</p>
      </v-card-title>
      <v-divider></v-divider>
      <v-row class="ma-0 pa-0 mt-4" justify="center">
        <vue-cropper
            ref="cropper"
            :aspect-ratio="1"
            :src="iconImgSrc"
        />
      </v-row>
      <v-card-actions>
        <v-col class="pa-0" style="text-align: center;">
          <v-btn
              color="green accent-4"
              class="mt-6 px-16 py-4 white--text"
              @click="cropImg"
          >
            確定
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import VueCropper from 'vue-cropperjs';


export default {
  name: "ProfileDialogProfImgEdit",
  data() {
    return {
      /* 成形後の画像格納データ */
      croppedImg: {
        blobData: '',
        urlData: ''
      },
      /* Style関連 */
      formColor: 'green accent-4',
      btnColor: 'green accent-4',
      btnFontColor: 'white--text',
      /* REST API 関連*/
      restApiBasePath: '',
      restApiMessagePath: '/api/message/',
    }
  },
  watch: {
    /* 画像ソース更新時に再描画 */
    iconImgSrc: function (src) {
      this.replaceImg(src);
    }
  },
  components: {
    VueCropper
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedProfImgEditDialog: Boolean,
    iconImgSrc: String
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token','userId'])
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog() {
      this.$emit('closeProfImgEditDialog')
    },
    /* crop結果を格納 */
    cropImg() {
      /* 画像データを成形 */
      let form = new FormData();
      const userId = this.userId;
      this.editIcon = this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        form.append('user_img', blob, userId + '.jpg');
      });
      /* 画像blobデータ格納 */
      this.croppedImg.blobData = form;
      this.croppedImg.urlData = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$emit('closeProfImgEditDialog', this.croppedImg);
    },
    replaceImg(src) {
      this.$refs.cropper.replace(src);
    }
  }
}
</script>

<style scoped>
</style>