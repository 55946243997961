<template>
  <v-dialog
      v-model="openedDisclosureDialog"
      @click:outside="closeThisDialog"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="700"
  >
    <v-card class="pa-10">
      <v-card-title class="headline" :class="$vuetify.breakpoint.xs ? 'pa-2' : 'ma-0 pa-0'">
        <p class="ma-0 pa-0" style="width: 100%;"
           :class="$vuetify.breakpoint.xs ? 'pl-2 pr-1' : 'px-2'"
           :style="$vuetify.breakpoint.xs ? 'width: 100%; font-size: 20px;' : ''">
          <v-icon @click="closeThisDialog"
                  v-if="$vuetify.breakpoint.xs"
                  :style="$vuetify.breakpoint.xs ? 'position: absolute; top: 12px; left: 12px;' : ''">
            mdi-close-thick
          </v-icon>
          <br v-if="$vuetify.breakpoint.xs"/>
          {{ this.disclosure.title }}
        </p>
      </v-card-title>
      <v-row class="mx-0 my-4 pa-0" :class="$vuetify.breakpoint.xs ? 'ml-2' : ''">
        <v-avatar @click="toProfile(disclosure.userId)" style="cursor: pointer;">
          <v-img v-if="this.disclosure.userImg" :src="imgPath + this.disclosure.userImg"/>
          <v-img v-else src="@/assets/default_avatar.jpeg"/>
        </v-avatar>
        <p class="text-ellipsis my-0 ml-2"
           style="font-weight: bold; opacity: 0.75;"
           :style="$vuetify.breakpoint.xs ? 'width: calc(100vw - 180px);' : 'width: 400px;'">
          <span v-if="this.disclosure.groupName" style="color: darkslateblue;">
            {{ this.disclosure.groupName }}<br/>
          </span>
          <span :style="this.disclosure.groupName ? '' : 'line-height: 48px;'">
            {{ this.disclosure.userName }}
          </span>
          <span
              :style="$vuetify.breakpoint.xs
               ? this.disclosure.groupName ? 'position: absolute; right: 24px;' : 'position: absolute; right: 24px; line-height: 48px;'
                : 'position: absolute; right: 64px;'">
            {{ this.disclosure.insertDatetime }}
          </span>
        </p>
      </v-row>
      <v-row class="my-2" :class="$vuetify.breakpoint.xs ? 'mx-2' : 'mx-0'">
        <span v-if="this.disclosure.kind === '1'" class="disclosure-tag" style="background-color: #F44336;">案件情報</span>
        <span v-if="this.disclosure.kind === '2'" class="disclosure-tag" style="background-color: #2196F3;">人材情報</span>
        <span v-if="this.disclosure.kind === '3'" class="disclosure-tag" style="background-color: #FF9800;">交流会</span>
        <span v-if="this.disclosure.kind === '5'" class="disclosure-tag" style="background-color: #4CAF50;">その他</span>
        <span v-if="this.disclosure.limit === '2'" class="bp-only">BP限定情報</span>
      </v-row>
      <v-row class="px-8 mt-6 pb-4">
        <p class="mt-2" style="white-space: pre-line; word-wrap: break-word;"
           :style="$vuetify.breakpoint.xs ? 'min-height: 120px;' : 'min-height: 360px;'">
          {{ this.disclosure.description }}
        </p>
      </v-row>
      <v-row class="px-8 mb-4">
        <a v-if="this.disclosure.file"
           :href="parseFile(this.disclosure.file)"
           style="color: #00C853;"
           :style="$vuetify.breakpoint.xs ? 'font-size: 12px;' : ''"
           download
        >
          {{ getFileName(this.disclosure.file) }}
        </a>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "MessageDisclosureDialog",
  data() {
    return {}
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedDisclosureDialog: Boolean,
    disclosure: Object
  },
  computed: {
    /* 画像URLのベースパス */
    basePath: function () {
      return process.env.VUE_APP_URL
    },
    imgPath: function () {
      return this.basePath + '/media/'
    }
  },
  methods: {
    /* プロフィール遷移処理 */
    toProfile(user_id) {
      this.$router.push('/profile/' + user_id).catch(err => {
        console.log(err);
      });
    },
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog() {
      this.$emit('closeDisclosureDialog', false)
    },
    /* ファイル名を取得 */
    getFileName(file) {
      return file.split('/').slice(-1)[0];
    },
    /* ファイル情報成形処理 */
    parseFile(file) {
      let ret = null;
      if (file) {
        ret = this.basePath + '/media/' + file
      }
      return ret
    },
  }
}
</script>

<style scoped>
/* タイトルフォント */
.card-title {
  font-size: 22px !important;
}

/* 本文フォント */
.description {
  white-space: pre-line;
  word-wrap: break-word;
}

.bp-only {
  padding: 2px 12px;
  font-weight: bold;
  font-size: 13px;
  color: #00C853;
  cursor: default;
}
.disclosure-tag {
  display: inline-block;
  height: 20px;
  padding: 2px 12px;
  border-radius: 2px;
  color: white;
  font-weight: bold;
  font-size: 12px;
}
</style>