<template>
  <div>
    <NaviLeft v-if="$vuetify.breakpoint.smAndUp"/>
    <SpNavi v-else/>
  </div>
</template>

<script>
import SpNavi from '@/components/organisms/navi/sp/SpNavi'
import NaviLeft from '@/components/organisms/navi/pc/PcNaviHeader.vue'

export default {
  name: "NaviHeader",
  components: {
    NaviLeft,
    SpNavi,
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>