<template>
  <v-dialog
      v-model="openedMessageDialog"
      @click:outside="closeThisDialog"
      :content-class="$vuetify.breakpoint.xs ? 'mx-0' : ''"
      max-width="600"
  >
    <v-card :class="$vuetify.breakpoint.xs ? '' : 'pa-10'">
      <v-card-title
          class="headline"
          :style="$vuetify.breakpoint.xs ? 'text-align: center;' : ''">
        <p class="ma-0 pa-0 pl-2" style="width: 100%;"
           :style="$vuetify.breakpoint.xs ? 'width: 100%; text-align: center;' : ''">
          新規メッセージ作成
          <v-icon @click="closeThisDialog" v-if="$vuetify.breakpoint.xs"
                  :style="$vuetify.breakpoint.xs ? 'position: absolute; top: 20px; left: 18px;' : ''">
            mdi-close-thick
          </v-icon>
        </p>
      </v-card-title>
      <v-divider></v-divider>
      <v-row :class="$vuetify.breakpoint.xs ? 'mx-4 my-0 pa-0' : 'mx-4 my-2 pa-0'">
        <v-textarea
            label="本文を入力"
            name="input-7-4"
            :counter="999"
            :maxlength="999"
            :color="formColor"
            rows="8"
            required
            v-model="message.description"
        ></v-textarea>
      </v-row>
      <v-row class="ma-4"
             style="color: grey;"
             :style="$vuetify.breakpoint.xs ? 'font-size: 12px;' : 'font-size: 14px;'">
        送信されたメッセージやメッセージに対する返信は、<br/>メッセージページから確認できます。
      </v-row>
      <v-row :class="$vuetify.breakpoint.xs ? 'mx-4 my-0 pa-0' : 'ma-0 pa-0'">
        <v-col class="ma-0 pa-0"
               :cols="$vuetify.breakpoint.xs
                ? message.file.length === 0 ? 6 : 12
                : 8">
          <v-file-input
              truncate-length="0"
              label="ファイルを選択"
              v-model="message.file"
              color="green accent-4"
              @change="fileSizeCheck"
          ></v-file-input>
          <span v-if="invalidFileSize && message.file"
                class="ma-0 red--text"
                :style="$vuetify.breakpoint.xs ? 'font-size: 12px;' : 'font-size: 14px;'">
              ※ファイルサイズが超過しています(最大3MB)
          </span>
        </v-col>
        <v-col class="ma-0 pa-0" style="text-align: right;"
               :cols="$vuetify.breakpoint.xs
                ? message.file.length === 0 ? 6 : 12
                : 4">
          <v-btn
              @click="sendMessage()"
              :disabled="invalidFileSize || (message.file.length === 0 && !message.description)"
              width=120
              :color="btnColor"
              class="mt-4"
              :class="btnFontColor"
          >
            送信
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import {mapState} from 'vuex';


export default {
  name: "DisclosureMessageDialog",
  data() {
    return {
      /* フォーム入力値 */
      message: {
        description: '',
        file: []
      },
      /* ファイルサイズが有効かの状態 */
      invalidFileSize: false,
      /* Style関連 */
      formColor: 'green accent-4',
      btnColor: 'green accent-4',
      btnFontColor: 'white--text',
      /* REST API 関連*/
      restApiBasePath: '',
      restApiMessagePath: '/api/message/',
      restApiMessageFilePath: '/api/message/file/'
    }
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedMessageDialog: Boolean,
    disclosureId: String,
    otherId: String,
    title: String
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token', 'userId'])
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog() {
      this.$emit('closeMessageDialog', false);
      this.message.description = '';
      this.message.file = [];
    },
    /* メッセージ送信処理 */
    sendMessage() {
      /* バックエンド向けのデータ作成 */
      const reqBody = {
        'title': this.title,
        'description': this.message.description,
        'message_id': '',
        'disclosure_id': this.disclosureId,
        'user_id': this.userId,
        'other_id': this.otherId,
      };
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      /* バックエンドのAPI呼び出し */
      axios.post(this.restApiBasePath + this.restApiMessagePath, reqBody, reqHeader).then(res => {
        if (res.status.toString() === '200') {
          /* 添付ファイルが存在する場合は送信処理呼び出し */
          if (!(this.message.file.length === 0)) {
            this.sendMessageFile(res.data.message_id)
          }
          /* フォーム入力値を削除 */
          this.message.title = '';
          this.message.description = '';
          this.closeThisDialog()
        }
      }).catch(e => {
        console.log(e.message);
      });
    },
    /* ファイル送信処理 */
    sendMessageFile(messageId) {
      /* バックエンド向けのデータ作成 */
      let form = new FormData();
      let file = this.message.file;
      form.append('file', file);
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      /* バックエンドのAPI呼び出し */
      axios.put(this.restApiBasePath + this.restApiMessageFilePath + messageId + '/', form, reqHeader).then(res => {
        if (res.status.toString() === '200') {
          /* フォーム入力値を削除 */
          this.message.file = [];
        }
      }).catch(e => {
        console.log(e.message);
      });
    },
    /* アップロードファイルのサイズチェック */
    fileSizeCheck() {
      // ファイル削除時にundefinedではなく[]を設定する
      if (!this.message.file) {
        this.message.file = [];
      }
      // ファイルサイズ（制限）
      const maxSize = 1024 * 1024 * 3;
      // ファイルサイズが制限内かどうか判別
      this.invalidFileSize = this.message.file.size > maxSize;
    }
  }
}
</script>

<style scoped>
</style>