<template>
  <div>
    <v-row no-gutters class="ma-0 pa-0">
      <v-list flat width="100%" class="ma-0 pa-0">
        <v-list-item-group
            v-for="(content,$index) in contentsList"
            :key="$index"
        >
          <v-list-item :ripple="false">
            <v-list-item-avatar @click="toProfile(content)">
              <v-img v-if="content.user__img" :src="basePath + '/media/' + content.user__img"/>
              <v-img v-else src="@/assets/default_avatar.jpeg"/>
            </v-list-item-avatar>
            <v-badge
                :style="content.user__group__name
                 ? content.limit === '2' ? 'position: relative; bottom: -23px; left: -20px;' : 'position: relative; bottom: -20px; left: -14px;'
                 : content.limit === '2' ? 'position: relative; bottom: -17px; left: -20px;' : 'position: relative; bottom: -12px; left: -14px;'"
                :dot="content.limit !== '2'"
                content="B"
                :color="content.kind === '1' ? 'red'
                       :content.kind === '2' ? 'blue'
                       :content.kind === '3' ? 'orange'
                       :content.kind === '5' ? 'green' : ''"
            ></v-badge>
            <v-list-item-content
                @click="openDisclosureDialog(content)"
            >
              <v-list-item-title
                  style="font-weight: bold; font-size: 14px; opacity: 0.75;"
                  :style="$vuetify.breakpoint.xs ? 'font-size: 13px;' : ''">
                <p class="text-ellipsis ma-0 pa-0"
                   :style="$vuetify.breakpoint.xs ? 'width: calc(100vw - 180px);' : 'width: calc(100vw - 540px);'">
                  <span
                      v-if="content.user__group__name"
                      style="color: darkslateblue;"
                  >
                    {{ content.user__group__name }}<br/>
                  </span>
                  {{ content.user__name }}
                </p>
                <span style="position: absolute; top: 12px; right: 32px;">
                  {{ parseTime(content.insert_datetime) }}
                </span>
                <v-menu v-if="(content.user__id !== userId ) && (userId)" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-icon
                        style="position: absolute; right: 12px;"
                        :style="$vuetify.breakpoint.xs ? 'top: 11px;' : 'top: 11px;'"
                        small
                        v-on="on"
                    >
                      mdi-dots-vertical
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item :ripple="false" @click.stop="openAlarmConfDialog(content)">
                      <v-list-item-title
                          style="line-height: 24px; font-size: 13px; font-weight: bold; opacity: 0.6;"
                      >
                        <v-icon style="opacity: 0.75;">mdi-account-off</v-icon>
                        この投稿を通報する
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item :ripple="false" @click.stop="openBlockConfDialog(content)">
                      <v-list-item-title
                          style="line-height: 24px; font-size: 13px; font-weight: bold; opacity: 0.6;"
                      >
                        <v-icon style="opacity: 0.75;">mdi-alarm-light-off</v-icon>
                        このユーザーをブロックする
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-title>
              <p class="text-ellipsis ma-0 pt-3 pb-2 pr-2"
                 style="font-weight: bold;"
                 :style="$vuetify.breakpoint.xs ? 'font-size: 14px;' : ''">
                {{ content.title }}
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-list-item-group>
        <InfiniteLoading :forceUseInfiniteWrapper="true" :identifier="infiniteId" @infinite="getContentsList">
          <span slot="no-more"></span>
          <span v-if="$route.path === '/'" slot="no-results">
            <span style="display: inline-block; margin-top: 32px; margin-bottom: 32px; font-size: 14px; opacity: 0.8;">検索結果はありません</span>
          </span>
          <span v-if="$route.path.match('profile')" slot="no-results">
            <span style="display: inline-block; margin-top: 32px; margin-bottom: 32px; font-size: 14px; opacity: 0.8;">投稿情報はありません</span>
          </span>
        </InfiniteLoading>
      </v-list>
    </v-row>
    <v-divider/>
    <DisclosureDialog
        :openedDisclosureDialog="openedDisclosureDialog"
        :selectedContent="selectedContent"
        @closeDisclosureDialog="closeDisclosureDialog"
    />
    <MessageBlockConfDialog
        :openedBlockConfDialog="openedBlockConfDialog"
        :otherId="blockId"
        @closeBlockConfDialog="closeBlockConfDialog"
    />
    <AlarmDisclosureConfDialog
        :openedAlarmConfDialog="openedAlarmConfDialog"
        :disclosureId="alarmId"
        @closeAlarmConfDialog="closeAlarmConfDialog"
    />
  </div>
</template>

<script>
import axios from 'axios';
import {mapState} from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import DisclosureDialog from '@/components/molecules/common/DisclosureDialog.vue';
import MessageBlockConfDialog from '@/components/molecules/message/MessageBlockConfDialog.vue';
import AlarmDisclosureConfDialog from '@/components/molecules/common/AlarmDisclosureConfDialog.vue';


export default {
  name: "ContentList",
  data() {
    return {
      /* 表示する案件リスト */
      contentsList: [],
      /* 案件リストの取得件数 */
      count: 1,
      /* リスト再取得のためのリセット変数 */
      infiniteId: +new Date(),
      /* 選択された案件 */
      selectedContent: {},
      /* ブロック対象のユーザID */
      blockId: '',
      /* 通報対象の案件ID */
      alarmId: '',
      /* REST API 関連*/
      restApiBasePath: '',
      restApiDisclosureListPath: '/api/disclosure/list/',
      /* 子ダイアログの状態管理 */
      openedDisclosureDialog: false,
      openedBlockConfDialog: false,
      openedAlarmConfDialog: false,
    }
  },
  components: {
    DisclosureDialog,
    MessageBlockConfDialog,
    AlarmDisclosureConfDialog,
    InfiniteLoading
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['userId', 'token', 'globalSearchString', 'globalMainReload']),

    /* 画像URLのベースパス */
    basePath: function () {
      return process.env.VUE_APP_URL
    }
  },
  props: {
    /* 検索条件を親コンポーネントから取得 */
    searchVal: Object
  },
  watch: {
    /* ページ遷移を監視しリスト情報を再取得 */
    '$route': function () {
      this.reload();
    },
    /* BPフィルターを監視し設定情報を再取得 */
    'searchVal.bpFilter': function () {
      this.reload();
    },
    /* 検索条件を監視しリスト情報を再取得 */
    'searchVal.typeFilter': function () {
      this.reload();
    },
    'searchVal.searchString': function () {
      this.reload();
    },
    'globalSearchString': function () {
      this.reload();
    },
    'globalMainReload': function () {
      this.reload();
    }
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* クエリ情報取得 */
    getQuery(){
      /* クエリが存在しなければリターン */
      if(!Object.keys(this.$route.query).length) {
        return;
      }

      /* 案件情報を取得できていればダイアログを開く */
      if (this.contentsList.length){
        this.openDisclosureDialog(this.contentsList[0]);
      }

      /* URLのクエリ情報を書き換える */
      this.$router.replace('/').catch(err => {
        console.log(err);
      });
    },
    /* プロフィール遷移処理 */
    toProfile(content) {
      this.$router.push('/profile/' + content.user__id).catch(err => {
        console.log(err);
      });
    },
    /* 案件情報取得処理 */
    getContentsList($state) {
      /* バックエンド向けのデータ作成 */
      let otherId = "";
      /* プロフィールページの場合表示する対象のIDをセット */
      if (this.$route.path !== "/") {
        otherId = this.$route.params.id + '/'
      }
      /* BPの投稿のみ表示の状態を取得 */
      let isBp = this.searchVal.bpFilter;
      /* フィルター情報の取得 */
      let kind_list = this.searchVal.typeFilter ? this.searchVal.typeFilter : ['1', '2', '3', '5'];
      /* 検索キーワードの取得 */
      let searchString = this.globalSearchString ? this.globalSearchString : this.searchVal.searchString;
      const reqBody = {
        'user_id': this.userId,
        'is_bp': isBp,
        'kind_list': kind_list,
        'search_string': searchString,
        'count': this.count.toString(),
      };
      /* バックエンドのAPI呼び出し */
      axios.post(this.restApiBasePath + this.restApiDisclosureListPath + otherId, reqBody,).then(res => {
        if (res.status.toString() === '200') {
          this.count += 1;
          /* 取得した案件リストを格納 */
          this.contentsList.push(...res.data);
          $state.loaded();
        } else if (res.status.toString() === '204') {
          $state.complete();
          /* 全取得後、URLクエリが存在するかチェック */
          this.getQuery();
        }
      }).catch(e => {
        console.log(e.message);
      });
    },
    /* 時刻情報成形処理 */
    parseTime(insertDatetime) {
      let ts = Date.parse(insertDatetime);
      const dt = new Date(ts);
      const year = dt.getFullYear();
      const month = dt.getMonth() + 1;
      const days = dt.getDate();
      return year + "/" + month + "/" + days;
    },
    /* 案件表示ダイアログのオープン処理 */
    openDisclosureDialog(content) {
      this.selectedContent = content;
      this.openedDisclosureDialog = true;
    },
    /* ブロック確認ダイアログのオープン処理 */
    openBlockConfDialog(content) {
      this.blockId = content.user__id;
      this.openedBlockConfDialog = true;
    },
    /* 通報確認ダイアログのオープン処理 */
    openAlarmConfDialog(content) {
      this.alarmId = content.id;
      this.openedAlarmConfDialog = true;
    },
    /* 案件表示ダイアログのクローズ処理 */
    closeDisclosureDialog(deleted) {
      this.openedDisclosureDialog = false;
      if (deleted) {
        this.reload()
      }
    },
    /* ブロック確認ダイアログのクローズ処理 */
    closeBlockConfDialog() {
      this.openedBlockConfDialog = false;
      /* リストの再取得 */
      this.reload()
    },
    /* 通報確認ダイアログのクローズ処理 */
    closeAlarmConfDialog() {
      this.openedAlarmConfDialog = false;
    },
    /* チャットリストのリロード */
    reload() {
      this.contentsList = [];
      this.count = 1;
      this.infiniteId += 1;
    }
  }
}
</script>

<style scoped>

</style>