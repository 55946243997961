<template>
    <v-btn
        @click="openDialog(otherId)"
        :color="buttonColor"
        :class="buttonFontColor"
        style="position: absolute;"
        :style="$vuetify.breakpoint.xs ? 'right: 16px;' : 'right: 32px;'"
        rounded
        height="40"
        width="120"
        max-width="120"
    >
      <v-icon style="position: absolute; left: 0; font-size: 20px;" color="white">mdi-handshake-outline</v-icon>
      <b>BP</b>
      <BpDialogDelConform :otherId="otherId" :opened="opened" @enterCloseBtn="closeDialog" />
    </v-btn>
</template>

<script>
import BpDialogDelConform from '@/components/molecules/bp/BpDialogDelConform.vue'

export default {
  name: "BpDeletionBtn",
  data() {
    return {
      buttonColor: 'green accent-4',
      buttonFontColor: 'white--text',
      opened: false
    }
  },
  components: {
    BpDialogDelConform
  },
  props: {
    /* 親コンポーネントから申請相手のユーザIDを受け取り */
    otherId: String
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* ダイアログオープン処理 */
    openDialog() {
      this.opened = true
    },
    /* ダイアログクローズ処理 */
    closeDialog() {
      /* ダイアログのクローズを親コンポーネントへ通知 */
      this.$emit('enterBpRequestBtn', true)
      this.opened = false
    }
  }
}
</script>

<style scoped></style>