<template>
  <v-navigation-drawer
      app
      left
      :permanent="$vuetify.breakpoint.smAndUp"
      width="350"
      :color="baseColor"
      class="scrollbar"
    >
    <v-sheet :color="baseColor" width="100%" class="pl-14">
      <v-list flat>
        <template v-if="userId">
          <v-list-item-group :class="naviFont">
            <v-list-item :to="'/profile/' + userId" :ripple="false" class="py-2 px-3 font-weight-bold">
              <v-list-item-avatar class="mr-8" :class="outlined">
                <v-img v-if="userInfo.img" :src="userInfo.img" />
                <v-img v-else src="@/assets/default_avatar.jpeg" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle
                    v-if="userInfo.groupName"
                    :style="($route.path === '/profile/' + userId) ? 'color: #00C853;' : 'color: darkslateblue;'"
                >
                  {{ userInfo.groupName }}
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ userInfo.name }}
                  <span v-if="!userImg" style="font-weight: normal; font-size: 12px;"><br/>プロフィール編集はコチラ</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item to="/" :ripple="false" active-class="font-weight-bold" class="pt-3 pb-1">
              <v-list-item-icon>
                <v-icon large>mdi-magnify</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>検索</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/bp" :ripple="false" active-class="font-weight-bold"
                         class="py-1">
              <v-list-item-icon>
                <v-icon large>mdi-handshake-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>BP管理</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/message" :ripple="false" active-class="font-weight-bold" class="py-1">
              <v-list-item-icon>
                <v-icon large>mdi-email-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>メッセージ</v-list-item-title>
                <span v-if="noReadCount > 0">
                  <span style="position: absolute; bottom: 34px; right: 48px;">
                    <v-tab>
                      <v-badge
                          color="red"
                          :content="noReadCount"
                      ></v-badge>
                    </v-tab>
                  </span>
                </span>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group>
            <v-list-item @click.stop="openedSettingsDialog=true" :ripple="false" class="py-1">
              <v-list-item-icon>
                <v-icon large :color="iconColor">mdi-cog-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>設定</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group v-if="(userId) && (($route.path === '/') || ($route.path === '/profile/' + userId))">
            <v-list-item  class="pt-16" :ripple="false">
              <v-btn
                  :color=buttonColor
                  class="white--text"
                  height="60"
                  width="250"
                  @click.stop="openedDiscloseDialog = true"
              >
                <b>情報を発信する</b>
              </v-btn>
            </v-list-item>
          </v-list-item-group>
        </template>
        <template v-else>
          <v-list-item-group>
            <v-list-item @click.stop="openedLoginDialog=true" class="py-2">
              <v-list-item-avatar>
                <v-icon large :color="iconColor">mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>ゲスト</v-list-item-title>
                <v-list-item-subtitle>ログイン、新規登録はコチラ</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list-item-group>
          <v-list-item-group :class="naviFont">
            <v-list-item to="/" :ripple="false" active-class="font-weight-bold" class="pt-3 pb-1">
              <v-list-item-icon>
                <v-icon large>mdi-magnify</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>検索</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </template>
      </v-list>
      <NaviDialogLogin :openedLoginDialog="openedLoginDialog" @doneAuth="closeLoginDialog" />
      <NaviDialogDisclose :openedDiscloseDialog="openedDiscloseDialog" @doneDisclosure="closeDiscloseDialog"/>
      <NaviDialogSettings
          :openedSettingsDialog="openedSettingsDialog"
          @doneSettings="closeSettingsDialog"
          @changeSettings="changeSettings"
      />

      <v-dialog

          v-model="logoutDialog"
          max-width="420"
      >
        <v-card class="px-10 py-6">
          <v-card-text class="px-6 pt-10 pb-6" style="font-weight: bold; text-align: center;">
            ログアウトしますか?
          </v-card-text>
          <v-card-actions style="text-align: center;">
            <v-col>
              <v-btn
                  color="red"
                  class="white--text"
                  @click="logout()"
              >
                ログアウト
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                  color="grey"
                  class="white--text"
                  @click="logoutDialog=false"
              >
                キャンセル
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="deleteAccountDialog"
          max-width="480"
      >
        <v-card class="px-10 py-6">
          <v-card-text class="px-6 pt-10 pb-6" style="font-weight: bold; text-align: center;">
            アカウントを退会しますか？<br>
            <p class="pt-4 ma-0" style="text-align: left; font-size: 12px;">※法人ドメインのメールアドレスではない場合、<br>
              過去に登録したことがあるメールアドレスでの再登録は、<br>
              不可能となります。</p>
          </v-card-text>
          <v-card-actions style="text-align: center;">
            <v-col>
              <v-btn
                  color="red"
                  class="white--text"
                  @click="deleteUser()"
              >
                退会する
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                  color="grey"
                  class="white--text"
                  @click="deleteAccountDialog=false"
              >
                キャンセル
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </v-navigation-drawer>
</template>

<script>
import axios from 'axios';
import {mapMutations, mapState} from 'vuex';
import NaviDialogLogin from '@/components/molecules/navi/NaviDialogLogin.vue'
import NaviDialogDisclose from '@/components/molecules/navi/NaviDialogDisclose.vue'
import NaviDialogSettings from '@/components/molecules/navi/NaviDialogSettings.vue'


export default {
  name: "NaviLeft",
  components: {
    NaviDialogLogin,
    NaviDialogDisclose,
    NaviDialogSettings
  },
  mounted() {
    if (this.userId) {
      this.getUserInfo();
      this.initOutline();
      this.getNoReadCount();
      window.setInterval(() => {
        this.getNoReadCount();
      }, 60000);
    }
  },
  data() {
    return {
      // style
      baseColor: 'grey lighten-3',
      iconColor: 'grey darken-1',
      buttonColor: 'green accent-4',
      naviFont: 'green--text text--accent-4',
      defaultColor: 'gray--text text--darken-1',
      naviItmColor: {
        explore: 'grey darken-1',
        bp: 'grey darken-1',
        message: 'grey darken-1',
      },
      checkBoxColor: 'green accent-4',
      // green accent-4

      // メッセージ未読数
      noReadCount: '',

      selectedProfile: false,
      activeNaviItm: 'test',

      // 制御
      can_login: false,
      can_confirm: false,

      // エラーメッセージ(フラグ)
      loginError: false,
      registerError: false,
      // エラーメッセージ
      errorMessage: '',

      // ダイアログ表示用のフラグ
      loginDialog: false,
      registerDialog: false,
      hashDialog: false,
      writeDisclosureDialog: false,
      settingsDialog: false,
      logoutDialog: false,
      deleteAccountDialog: false,

      // 設定フラグ
      settings: {
        messageSendMail: false,
        bpSendMail: false,
        canFindName: false,
      },

      // 設定種別（固定値）
      msmKind: '1',
      bsmKind: '2',
      cfnKind: '5',

      // User Info
      userInfo: {
        userId: '',
        token: '',
        name: '',
        img: '',
        groupName: '',
        Key: '',
      },
      img: '',
      send_info: {
        title: "",
        body: "",
        file: ""
      },
      valid: true,
      credentials: {},
      rules: {
        email: [
          v => !!v || "ユーザー名は必須です",
          v => (v && v.length > 4) || "ユーザー名は5文字以上でなければなりません",
        ],
        password: [
          v => !!v || "パスワードは必須です",
          v => (v && v.length > 4) || "ユーザー名は5文字以上でなければなりません"
        ]
      },
      login_state: false,
      showPassword: false,
      test_token: "",
      user_id: "",

      // add register
      hash_cd: '',
      invite_email: '',
      axios_res: '',
      axios_get_res: '',

      // CSS
      outlined: '',

      //案件情報投稿 チェックボックス
      selected: '',

      //案件情報投稿 公開範囲設定
      selectedBpOnly: false,

      /* ダイアログの状態管理 */
      openedLoginDialog: false,
      openedDiscloseDialog: false,
      openedSettingsDialog: false
    }
  },
  watch: {
    '$route': function () {
      this.initOutline();
    },
    'globalNaviReload': function () {
      if (this.globalNaviReloadTarget === 'getNoReadCount') {
        this.getNoReadCount();
      }
      if (this.globalNaviReloadTarget === 'getUserInfo') {
        this.getUserInfo();
      }
    }
  },
  methods: {
    /* Vuex展開 */
    ...mapMutations([
      'setGlobalMainReload',
    ]),

    changeSettings(eventVal) {
      if(eventVal[1] === 1) {
        this.settings.messageSendMail = eventVal[0]
      } else if(eventVal[1] === 2) {
        this.settings.bpSendMail = eventVal[0]
      } else if(eventVal[1] === 5) {
        this.settings.canFindName = eventVal[0]
      } else {
        console.log('error')
      }
    },
    closeLoginDialog(doneAuth) {
      if (doneAuth) {
        this.getUserInfo();
        this.setGlobalMainReload(!this.globalMainReload);
      }
      this.openedLoginDialog = false;
    },
    closeDiscloseDialog() {
      this.openedDiscloseDialog = false;
    },
    closeSettingsDialog() {
      this.openedSettingsDialog = false;
    },
    initOutline () {
      if (this.$route.path === '/profile/' + this.userId) {
        this.outlined = 'outlined';
      } else {
        this.outlined = '';
      }
    },

    // メッセージ未読数取得
    getNoReadCount: function () {
      if (!this.loginState) {
        return;
      }
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      axios.get(process.env.VUE_APP_URL + '/api/message/notice/' + this.userId + '/', reqHeader).then(res => {
        if (res.status.toString() === '200') {
          this.noReadCount = res.data.message_count;
        }
      }).catch(e => {
        console.log(e.message);
      });
    },
    // ユーザ情報取得処理
    getUserInfo () {
      axios.get(process.env.VUE_APP_URL + '/api/user/' + this.userId + '/').then(res => {
        this.userInfo.key = res.data.key;
        this.userInfo.name = res.data.name;
        if (res.data.img) {
          this.userInfo.img = this.basePath + '/media/' + res.data.img + '?' + Math.random();
        } else {
          this.userInfo.img = '';
        }
        this.userInfo.groupName = res.data.group__name;
        this.settings.messageSendMail = res.data.should_send_message;
        this.settings.bpSendMail = res.data.should_send_bp;
        this.settings.canFindName = res.data.can_find_name;
        this.setUserKey(this.userInfo.key);
        this.setUserName(this.userInfo.name);
        this.setUserImg(this.userInfo.img);
        this.setGroupName(this.userInfo.groupName);
      }).catch(e => {
        console.log("error");
        console.log(e.message);
      });
    },
    setLoginState: function (state) {
      this.$store.commit('setLoginState', state);
    },
    setUserName: function (user_name) {
      this.$store.commit('setUserName', user_name);
    },
    setGroupName: function (groupName) {
      this.$store.commit('setGroupName', groupName);
    },
    setUserId: function (userId) {
      this.$store.commit('setUserId', userId);
    },
    setUserKey: function (userKey) {
      this.$store.commit('setUserKey', userKey);
    },
    setUserImg: function (userImg) {
      this.$store.commit('setUserImg', userImg);
    },
    setToken: function (token) {
      this.$store.commit('setToken', token);
    },
    setProfileUserId: function (profileUserId) {
      this.$store.commit('setProfileUserId', profileUserId)
    },
    toProfile() {
      this.setProfileUserId(this.userId);
      this.$router.push('/profile/' + this.userId).catch(err => {
        console.log(err)
      });
      this.selectedProfile = true;
    },
    deleteUser: function () {
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      axios.delete(process.env.VUE_APP_URL + '/api/user/' + this.userInfo.userId + '/', reqHeader).then(res => {
        if (res.status.toString() === '200') {
          this.logout();
          this.deleteAccountDialog = false;
        }
      }).catch(e => {
        console.log("error");
        console.log(e.message);
      });
    },
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['globalMainReload']),

    loginState: function () {
      return this.$store.state.loginState;
    },
    userName: function () {
      return this.$store.state.userName;
    },
    groupName: function () {
      return this.$store.state.groupName;
    },
    userId: function () {
      return this.$store.state.userId;
    },
    userKey: function () {
      return this.$store.state.userKey;
    },
    userImg: function () {
      return this.$store.state.userImg;
    },
    token: function () {
      return this.$store.state.token;
    },
    profileUserId: function () {
      return this.$store.state.profileUserId
    },
    /* MainからNaviへの更新管理 */
    globalNaviReload: function () {
      return this.$store.state.globalNaviReload
    },
    /* MainからNaviへの更新ターゲット情報 */
    globalNaviReloadTarget: function () {
      return this.$store.state.globalNaviReloadTarget
    },
    basePath: function () {
      return process.env.VUE_APP_URL
    }
  }
}
</script>

<style scoped>
  .outlined {
    border: 2px solid #00C853;
    border-radius:50%;
    height: 56px;
    width: 56px;
  }

  .scrollbar {
    overflow: auto;
  }

  /*スクロールバー全体*/
  scrollbar::-webkit-scrollbar {
    width: 10px;
  }

  /*スクロールバーの軌道*/
  .scrollbar::-webkit-scrollbar-track {
    border-radius: 20px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
  }

  /*スクロールバーの動く部分*/
  .scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 50, .5);
    border-radius: 10px;
    box-shadow:0 0 0 1px rgba(255, 255, 255, .3);
  }
</style>