<template>
  <div class="profilepage">
    <v-main>
      <v-container
              class="ma-0 pa-0"
      >
        <MainProfile/>
        <MainDivider/>
        <ContentList :searchVal="searchVal"/>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import MainProfile from '@/components/organisms/main/MainProfile.vue'
import MainDivider from '@/components/organisms/main/MainDivider.vue'
import ContentList from "../molecules/common/ContentList";

export default {
        metaInfo: {
          title: 'SE-Hub',
          titleTemplate: 'プロフィール | %s',
          meta: [
            { vmid: 'description', name: 'description',
              content: '今よりもっと効率よく「エンジニアを集める」「案件情報を探す」 を実現するサービス。 （機能の制限や有料プラン等は一切ありません。）'
            },
          ]
        },
        name: "ProfilePage",
        components: {
          ContentList,
          MainProfile,
          MainDivider,
        },
        data() {
            return {
              searchVal: {
                typeFilter: ['1', '2', '3', '5'],
                searchString: '',
              }
            }
        }
    }
</script>

<style scoped>

</style>