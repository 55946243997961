<template>
  <div>
    <v-row no-gutters class="ma-0 pa-0">
      <v-list width="100%" class="ma-0 pa-0">
        <v-list-item-group
            v-for="(userItm, index) in userList"
            :key="index"
        >
          <v-list-item class="py-2" :ripple="false" inactive>
            <v-list-item-avatar @click="toProfile(userItm.user__id)" class="bp-list-itm-avatar">
              <v-img v-if="userItm.user__img" :src="imgBasePath + userItm.user__img" />
              <v-img v-else src="@/assets/default_avatar.jpeg" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title @click="toProfile(userItm.user__id)" class="bp-list-itm-title">
                <span v-if="userItm.user__group__name" class="bp-list-itm-title-group-name">
                  {{ userItm.user__group__name }}<br/>
                </span>
                {{ userItm.user__name }}
              </v-list-item-title>
              <v-list-item-subtitle class="bp-list-itm-subtitle">
                {{ userItm.user__description }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <slot :otherId="userItm.user__id" />
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-list-item-group>
        <InfiniteLoading :forceUseInfiniteWrapper="true" :identifier="infiniteId" @infinite="getUserList">
          <span slot="no-more"></span>
          <span slot="no-results">
            <span style="display: inline-block; margin-top: 32px; margin-bottom: 32px; font-size: 14px; opacity: 0.8;">検索結果はありません</span>
          </span>
        </InfiniteLoading>
      </v-list>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState} from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';


export default {
  name: "BpUserList",
  data() {
    return {
      /* ユーザリスト */
      userList: [],
      /* リストの取得件数 */
      count: 1,
      /* リスト再取得のためのリセット変数 */
      infiniteId: +new Date(),
      /* 画像のベースパス */
      imgBasePath: '',
      /* REST API関連 */
      restApiBasePath: '',
      restApiBpListPath: '/api/bp/list/',
    }
  },
  components: {
    InfiniteLoading
  },
  props: {
    /* 親コンポーネントから表示するリストを受け取り */
    searchString: String,
    tabType: String,
    selectedTab: String,
    reload: Boolean,
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token','userId','globalSearchString'])
  },
  watch: {
    /* 選択されたTabの変更を感知しリストを取得 */
    selectedTab: function () {
      this.reloadList();
    },
    /* 入力された検索キーワードを感知しリストを取得 */
    searchString: function () {
      this.reloadList();
    },
    globalSearchString: function () {
      this.reloadList();
    },
    /* BPボタンが押されたときにリストを更新 */
    reload: function () {
      this.reloadList();
    }
  },
  created() {
    /* 画像パスの取得 */
    this.imgBasePath = process.env.VUE_APP_URL + '/media/';
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* プロフィールページへの遷移処理 */
    toProfile(userId) {
      this.$router.push('/profile/' + userId).catch(err => {
        console.log(err)
      });
    },
    /* ユーザリスト取得 */
    getUserList($state) {
      /* バックエンド向けのデータ作成 */
      /* 検索キーワード・タブ情報の取得 */
      let searchString = this.globalSearchString ? this.globalSearchString : this.searchString;
      let tabType = this.tabType ? this.tabType : '0';
      const reqBody = {
        'bp_status': tabType,
        'search_string': searchString,
        'count': this.count.toString()
      };
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      /* バックエンドのAPI呼び出し */
      axios.post(this.restApiBasePath + this.restApiBpListPath + this.userId + '/', reqBody, reqHeader).then(res => {
        if (res.status.toString() === '200') {
          this.count += 1;
          /* 取得したユーザーリストを格納 */
          this.userList.push(...res.data);
          $state.loaded();
        } else if (res.status.toString() === '204') {
          $state.complete();
        }
      }).catch(() => {
        console.log('err')
      });
    },
    /* リストのリロード */
    reloadList() {
      this.userList = [];
      this.count = 1;
      this.infiniteId += 1;
    }
  }
}
</script>

<style scoped>
  /* リストアイテムアバターのカーソルアクション */
  .bp-list-itm-avatar {
    cursor: pointer;
  }

  /* リストアイテム名前部分のフォント・カーソルアクション */
  .bp-list-itm-title {
    font-weight: bold;
    font-size: 14px;
    opacity: 0.75;
    cursor: pointer;
  }

  /* リストアイテム会社名のフォント */
  .bp-list-itm-title-group-name {
    color: darkslateblue;
  }

  /* リストアイテム自己紹介部のフォント */
  .bp-list-itm-subtitle{
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>