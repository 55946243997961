<template>
  <v-dialog
      v-model="openedDisclosureDialog"
      @click:outside="closeThisDialog()"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="700"
  >
    <v-card class="pa-10">
      <v-card-title class="headline" :class="$vuetify.breakpoint.xs ? 'py-2 px-4' : 'px-1'">
        <p class="ma-0 py-0" style="width: 100%;"
           :class="$vuetify.breakpoint.xs ? '' : 'pr-1'"
           :style="$vuetify.breakpoint.xs ? 'width: 100%; font-size: 20px;' : ''">
          <v-icon @click="closeThisDialog()" v-if="$vuetify.breakpoint.xs"
                  :style="$vuetify.breakpoint.xs ? 'position: absolute; top: 12px; left: 12px;' : ''">
            mdi-close-thick
          </v-icon>
          <br v-if="$vuetify.breakpoint.xs"/>
          {{ selectedContent.title }}
        </p>
      </v-card-title>
      <v-row class="mx-0 my-4 pa-0" :class="$vuetify.breakpoint.xs ? 'ml-2' : ''">
        <v-avatar @click="toProfile(selectedContent)" style="cursor: pointer;">
          <v-img v-if="selectedContent.user__img" :src="imgPath + selectedContent.user__img"/>
          <v-img v-else src="@/assets/default_avatar.jpeg"/>
        </v-avatar>
        <p class="text-ellipsis my-0 ml-2"
           style="font-weight: bold; opacity: 0.75;"
           :style="$vuetify.breakpoint.xs ? 'width: calc(100vw - 180px);' : 'width: 400px;'">
          <span v-if="selectedContent.user__group__name" style="color: darkslateblue;">
            {{ selectedContent.user__group__name }}<br/>
          </span>
          <span :style="selectedContent.user__group__name ? '' : 'line-height: 48px;'">
            {{ selectedContent.user__name }}
          </span>
          <span
              :style="$vuetify.breakpoint.xs
               ? selectedContent.user__group__name ? 'position: absolute; right: 24px;' : 'position: absolute; right: 24px; line-height: 48px;'
                : 'position: absolute; right: 64px;'">
            {{ parseTime(selectedContent.insert_datetime) }}
          </span>
        </p>
      </v-row>
      <v-row class="my-2" :class="$vuetify.breakpoint.xs ? 'mx-2' : 'mx-0'">
        <span v-if="selectedContent.kind === '1'" class="disclosure-tag" style="background-color: #F44336;">案件情報</span>
        <span v-if="selectedContent.kind === '2'" class="disclosure-tag" style="background-color: #2196F3;">人材情報</span>
        <span v-if="selectedContent.kind === '3'" class="disclosure-tag" style="background-color: #FF9800;">交流会</span>
        <span v-if="selectedContent.kind === '5'" class="disclosure-tag" style="background-color: #4CAF50;">その他</span>
        <span v-if="selectedContent.limit === '2'" class="bp-only">BP限定情報</span>
      </v-row>
      <v-row class="my-2" :class="$vuetify.breakpoint.xs ? 'mx-2' : 'mx-0'">
        <v-snackbar v-model="snackbar" :top="true" :timeout="2000">
          URLをコピーしました
          <v-btn color="white" text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <span style="padding: 2px 12px; font-size: 12px; cursor:pointer;" @click="doCopy(); snackbar = true">{{createUrl}}</span>
        <span v-if="selectedContent.limit === '2'" style="padding: 2px 12px;" :style="$vuetify.breakpoint.xs ? 'font-size: 10px;' : 'font-size: 11px;'">※この投稿情報のURLは、この投稿ユーザーのBPのみ有効です。</span>
      </v-row>
      <v-row class="px-8 mt-6 mb-4">
        <p class="mt-2" style="white-space: pre-line; word-wrap: break-word;"
           :style="$vuetify.breakpoint.xs ? 'min-height: 120px;' : 'min-height: 360px;'">
          {{ selectedContent.description }}
        </p>
      </v-row>
      <v-row class="px-8 mb-4">
        <a v-if="selectedContent.file"
           :href="parseFile(selectedContent.file)"
           style="color: #00C853;"
           :style="$vuetify.breakpoint.xs ? 'font-size: 12px;' : ''"
           download
        >
          {{ getFileName(selectedContent.file) }}
        </a>
      </v-row>
      <v-card-actions>
        <v-col style="text-align: center;">
          <v-btn
              v-if="(selectedContent.user__id === this.userId)"
              color="red"
              class="white--text"
              @click="openDelConformDialog"
          >
            この投稿を削除する
          </v-btn>
          <DelConformDialog
              :openedDelConformDialog="openedDelConformDialog"
              :disclosureId="disclosureId"
              @closeDelConformDialog="closeDelConformDialog"
          />
          <v-btn
              v-if="(userId && selectedContent.user__id !== userId)"
              color="green accent-4"
              class="white--text"
              @click="openMessageDialog"
          >
            この投稿にメッセージを送信する
          </v-btn>
          <DisclosureMessageDialog
              :openedMessageDialog="openedMessageDialog"
              :disclosureId="disclosureId"
              :otherId="otherId"
              :title="title"
              @closeMessageDialog="closeMessageDialog"
          />
        </v-col>
      </v-card-actions>
      <v-card-text
          v-if="(!userId)"
          style="text-align: center;"
      >
        この投稿にメッセージを送るには、<br>
        ログインする必要があります。
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from 'vuex';
import DelConformDialog from '@/components/molecules/common/DelConformDialog.vue';
import DisclosureMessageDialog from '@/components/molecules/common/DisclosureMessageDialog.vue';

export default {
  name: "DisclosureDialog",
  data() {
    return {
      /* 案件ID */
      disclosureId: '',
      /* 相手ユーザID */
      otherId: '',
      /* メッセージダイアログに渡すタイトル */
      title: '',
      /* 子ダイアログの状態管理 */
      openedDelConformDialog: false,
      openedMessageDialog: false,
      /* URLの先頭部 */
      contentUrl: 'https://se-hub.jp/?ID=',
      /* スナックバー初期値 */
      snackbar: false
    }
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedDisclosureDialog: Boolean,
    selectedContent: Object
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token', 'userId']),
    /* 画像URLのベースパス */
    basePath: function () {
      return process.env.VUE_APP_URL
    },
    imgPath: function () {
      return this.basePath + '/media/'
    },
    /* 案件URLデータ作成 */
    createUrl(){
      return this.contentUrl + this.selectedContent.id;
    },
  },
  components: {
    DelConformDialog,
    DisclosureMessageDialog
  },
  watch: {
    'openedDisclosureDialog': function () {
      this.scrollReset();
    }
  },
  methods: {
    /* スクロールリセット */
    scrollReset() {
      /* v-dialogのelement取得 */
      const elements = document.getElementsByClassName('v-dialog--active');
      /* elementが無い場合はリターン */
      if (!elements || !elements.length) {
        return;
      }
      /* スクロールをトップにリセット */
      elements[0].scrollTop = 0;
    },
    /* プロフィール遷移処理 */
    toProfile(content) {
      this.$router.push('/profile/' + content.user__id).catch(err => {
        console.log(err);
      });
    },
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog(deleted = false) {
      this.$emit('closeDisclosureDialog', deleted)
    },
    /* 削除確認ダイアログクローズ処理 */
    closeDelConformDialog(deleted) {
      this.openedDelConformDialog = false;
      /* 案件投降が削除された場合、案件表示ダイアログを閉じる */
      if (deleted) {
        this.closeThisDialog(deleted);
      }
    },
    /* メッセージダイアログクローズ処理 */
    closeMessageDialog() {
      this.openedMessageDialog = false;
    },
    /* 削除確認ダイアログオープン処理 */
    openDelConformDialog() {
      this.disclosureId = this.selectedContent.id;
      this.openedDelConformDialog = true;
    },
    /* メッセージダイアログオープン処理 */
    openMessageDialog() {
      this.disclosureId = this.selectedContent.id;
      this.otherId = this.selectedContent.user__id;
      this.title = this.selectedContent.title;
      this.openedMessageDialog = true;
    },
    /* 時刻情報成形処理 */
    parseTime(insertDatetime) {
      let ts = Date.parse(insertDatetime);
      const dt = new Date(ts);
      const year = dt.getFullYear();
      const month = dt.getMonth() + 1;
      const days = dt.getDate();
      return year + "/" + month + "/" + days;
    },
    /* ファイル名取得 */
    getFileName(file) {
      return file.split('/').slice(-1)[0];
    },
    /* ファイル情報成形処理 */
    parseFile(file) {
      let ret = null;
      if (file) {
        ret = this.basePath + '/media/' + file
      }
      return ret
    },
    /* URLコピー */
    doCopy(){
      navigator.clipboard.writeText(this.createUrl);
    },
  }
}
</script>

<style scoped>
.bp-only {
  padding: 2px 12px;
  font-weight: bold;
  font-size: 13px;
  color: #00C853;
  cursor: default;
}
.disclosure-tag {
  display: inline-block;
  height: 20px;
  padding: 2px 12px;
  border-radius: 2px;
  color: white;
  font-weight: bold;
  font-size: 12px;
}
</style>