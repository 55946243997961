<template>
  <v-dialog
      v-model="openedMessageDialog"
      @click:outside="closeThisDialog"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="700"
  >
    <v-card class="pa-10" v-if="selectedMessage">
      <v-card-title class="headline" :class="$vuetify.breakpoint.xs ? 'pa-2' : 'ma-0 pa-0'">
        <p class="ma-0 pa-0"
           :class="$vuetify.breakpoint.xs ? 'pl-8' : 'pl-2'"
           style="width: 100%; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
           :style="$vuetify.breakpoint.xs ? 'width: 100%; font-size: 20px;' : ''">
          <v-icon @click="closeThisDialog" v-if="$vuetify.breakpoint.xs"
                  :style="$vuetify.breakpoint.xs ? 'position: absolute; top: 12px; left: 12px;' : ''">
            mdi-close-thick
          </v-icon>
          {{ selectedMessage.title }}
        </p>
      </v-card-title>
      <v-row v-if="userId !== selectedMessage.user1__id" class="mx-0 my-4 pa-0"
             :class="$vuetify.breakpoint.xs ? 'ml-2' : ''">
        <v-avatar>
          <v-img v-if="selectedMessage.user1__img"
                 :src="imgPath + selectedMessage.user1__img + '?' + Math.random()"
                 style="cursor: pointer;"
                 @click="toProfile(selectedMessage.user1__id)"/>
          <v-img v-else src="@/assets/default_avatar.jpeg"
                 style="cursor: pointer;"
                 @click="toProfile(selectedMessage.user1__id)"/>
        </v-avatar>
        <p class="text-ellipsis my-0 ml-2"
           style="font-weight: bold; opacity: 0.75;"
           :style="$vuetify.breakpoint.xs ? 'width: 200px;' : 'width: 400px;'">
          <span
              v-if="selectedMessage.user1__group__name"
              style="color: darkslateblue;"
          >
            {{ selectedMessage.user1__group__name }}<br/>
          </span>
          <span :style="selectedMessage.user1__group__name ? '' : 'line-height: 48px;'">
            {{ selectedMessage.user1__name }}
          </span>
        </p>
        <span
            style="cursor: pointer; line-height: 40px;"
            :style="$vuetify.breakpoint.xs
             ? 'position: absolute; right: 16px;'
              : 'position: absolute; right: 64px;'"
            @click="showDisclosure(selectedMessage);"
        >
          <v-icon>mdi-note-text-outline</v-icon>
        </span>
      </v-row>
      <v-row v-else class="mx-0 my-4 pa-0" :class="$vuetify.breakpoint.xs ? 'ml-2' : ''">
        <v-avatar>
          <v-img v-if="selectedMessage.user2__img"
                 :src="imgPath + selectedMessage.user2__img + '?' + Math.random()"
                 style="cursor: pointer;"
                 @click="toProfile(selectedMessage.user2__id)"/>
          <v-img v-else
                 src="@/assets/default_avatar.jpeg"
                 style="cursor: pointer;"
                 @click="toProfile(selectedMessage.user1__id)"/>
        </v-avatar>
        <p class="text-ellipsis my-0 ml-2"
           style="font-weight: bold; opacity: 0.75;"
           :style="$vuetify.breakpoint.xs ? 'width: 200px;' : 'width: 400px;'">
          <span
              v-if="selectedMessage.user2__group__name"
              style="color: darkslateblue;"
          >
            {{ selectedMessage.user2__group__name }}<br/>
          </span>
          <span :style="selectedMessage.user2__group__name ? '' : 'line-height: 48px;'">
            {{ selectedMessage.user2__name }}
          </span>
        </p>
        <span
            style="cursor: pointer; line-height: 40px;"
            :style="$vuetify.breakpoint.xs
             ? 'position: absolute; right: 16px;'
              : 'position: absolute; right: 64px;'"
            @click="showDisclosure(selectedMessage);"
        >
          <v-icon>mdi-note-text-outline</v-icon>
        </span>
      </v-row>
      <MessageDisclosureDialog
          :openedDisclosureDialog="openedDisclosureDialog"
          :disclosure="disclosure"
          @closeDisclosureDialog="closeDisclosureDialog"
      />
      <v-divider></v-divider>
      <v-list class="ma-0 pt-4 pb-0"
              style="overflow-y: auto;"
              flat
              :style="$vuetify.breakpoint.xs ? 'height: calc(100vh - 350px);' : 'min-height: 300px; height: calc(90vh - 420px);'">
        <InfiniteLoading direction="top" :distance="500" :identifier="infiniteId" @infinite="getChatList"
                         class="pa-0 ma-0">
          <span slot="no-more"></span>
          <span slot="no-results"></span>
        </InfiniteLoading>
        <div id="chat_list">
          <v-list-item-group
              v-for="(n,index) in chatList"
              :style="n.from_user__id === userId ? 'text-align: right;' : ''"
              :key="index"
          >
            <v-list-item
                v-if="index === 0"
                class="mb-4"
                style="cursor: default"
                :ripple="false">
              <span class="insert-date">{{ getDate(n.insert_datetime) }}</span>
            </v-list-item>
            <v-list-item
                v-if="index !== 0 && getDate(chatList[index].insert_datetime) !== getDate(chatList[index-1].insert_datetime)"
                class="my-4"
                style="cursor: default"
                :ripple="false">
              <span class="insert-date">{{ getDate(n.insert_datetime) }}</span>
            </v-list-item>
            <v-list-item
                v-if="n.from_user__id === userId"
                :ripple="false"
                class="balloon-right mb-4 rounded-lg"
                style="display: inline-block; max-width: 70%; margin-right: 16px; background-color: #c9ffdc;  cursor: initial; user-select: auto;">
              <span v-if="n.is_read" style=" font-size: 13px; position: absolute; left: -32px; bottom: 18px;">既読</span>
              <span style="font-size: 14px; position: absolute; left: -40px; bottom: 0;">
                {{ getTime(n.insert_datetime) }}
              </span>
              <v-list-item-content class="chat-message">
                <v-list-item-title
                    style="white-space: pre-line; word-wrap: break-word; text-align: left;">
                  {{ n.description }}
                </v-list-item-title>
                <a v-if="n.file"
                   :href="parseFile(n.file)"
                   style="font-size: 12px; margin-top: 4px; color: #00C853;"
                   download=""
                >{{ getFileName(n.file) }}</a>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-else
                :ripple="false"
                class="balloon-left mb-4 rounded-lg"
                style="display: inline-block; max-width: 70%; margin: 0 0 0 16px; background-color: #ececec; cursor: initial; user-select: auto;"
            >
            <span style="font-size: 14px; position: absolute; right: -40px; bottom: 0;">
              {{ getTime(n.insert_datetime) }}
            </span>
              <v-list-item-content class="chat-message">
                <v-list-item-title
                    style="white-space: pre-line; word-wrap: break-word; text-align: left;">
                  {{ n.description }}
                </v-list-item-title>
                <a v-if="n.file"
                   :href="parseFile(n.file)"
                   style="font-size: 12px; margin-top: 4px; color: #00C853;"
                   download
                >{{ getFileName(n.file) }}</a>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </div>
      </v-list>
      <v-divider></v-divider>
      <v-row class="ma-0 pa-0">
        <v-col cols="12" class="pb-0">
          <v-textarea
              v-model="chatMessage"
              label="メッセージを入力"
              :counter="999"
              :maxlength="999"
              color="green accent-4"
              class="pt-0"
              rows="4"
              required
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" :class="$vuetify.breakpoint.xs ? 'px-4' : ''">
        <v-col class="ma-0 pa-0"
               :cols="$vuetify.breakpoint.xs
                ? file.length === 0 ? 6 : 12
                : 8">
          <v-file-input
              truncate-length="0"
              label="ファイルを選択"
              v-model="file"
              color="green accent-4"
              @change="fileSizeCheck"
          ></v-file-input>
          <span v-if="invalidFileSize && file"
                class="ma-0 red--text"
                :style="$vuetify.breakpoint.xs ? 'font-size: 12px;' : 'font-size: 14px;'">
            ※ファイルサイズが超過しています(最大3MB)
          </span>
        </v-col>
        <v-col class="ma-0 pa-0" style="text-align: right;"
               :cols="$vuetify.breakpoint.xs
                ? file.length === 0 ? 6 : 12
                : 4">
          <v-btn
              @click="sendMessage()"
              :disabled="invalidFileSize || (file.length === 0 && !chatMessage)"
              width=120
              color="green accent-4"
              class="white--text my-4"
          >
            送信
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

  </v-dialog>
</template>

<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
import {mapState} from 'vuex';
import MessageDisclosureDialog from "./MessageDisclosureDialog";

export default {
  name: "MessageDialog",
  data() {
    return {
      /* チャット内容 */
      chatList: [],
      /* チャットリストの取得件数 */
      count: 1,
      /* リスト再取得のためのリセット変数 */
      infiniteId: +new Date(),
      /* 送信するチャットメッセージ */
      chatMessage: '',
      /* 送信する添付ファイル */
      file: [],
      /* 案件情報 */
      disclosure: {},
      /* ファイルサイズが有効かの状態 */
      invalidFileSize: false,
      /* Style関連 */
      formColor: 'green accent-4',
      btnColor: 'green accent-4',
      btnFontColor: 'white--text',
      /* REST API 関連*/
      restApiBasePath: '',
      restApiMessagePath: '/api/message/',
      restApiMessageFilePath: '/api/message/file/',
      /* 子ダイアログの状態管理 */
      openedDisclosureDialog: false,
    }
  },
  components: {
    MessageDisclosureDialog,
    InfiniteLoading
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedMessageDialog: Boolean,
    /* 選択されたメッセージ情報受け取り */
    selectedMessage: Object
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token', 'userId']),
    /* 画像URLのベースパス */
    basePath: function () {
      return process.env.VUE_APP_URL
    },
    imgPath: function () {
      return this.basePath + '/media/'
    }
  },
  watch: {
    /* 選択されたメッセージ情報が更新された際に、チャットリストを取得 */
    selectedMessage: function () {
      /* リスト再取得 */
      this.reload();
    }
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog() {
      this.$emit('closeMessageDialog', false)
      this.chatMessage = '';
      this.file = [];
    },
    /* 案件表示ダイアログのクローズ処理 */
    closeDisclosureDialog() {
      this.openedDisclosureDialog = false;
    },
    /* チャットリストの取得 */
    getChatList($state) {
      /* バックエンド向けのデータ作成 */
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      /* バックエンドのAPI呼び出し */
      axios.get(this.restApiBasePath + this.restApiMessagePath +
          this.selectedMessage.id + '/' + this.count + '/', reqHeader)
          .then(res => {
            if (res.status.toString() === '200') {
              /* 取得したチャットリストを格納 */
              this.count += 1;
              this.chatList.unshift(...res.data.reverse());
              $state.loaded();
            } else if (res.status.toString() === '204') {
              $state.complete();
              this.readMessage(this.selectedMessage.id);
            }
          }).catch(e => {
        console.log(e.message);
      });
    },
    /* メッセージ送信処理 */
    sendMessage() {
      /* バックエンド向けのデータ作成 */
      let otherId = (this.userId !== this.selectedMessage.user1__id)
          ? this.selectedMessage.user1__id : this.selectedMessage.user2__id;
      const requestBody = {
        'description': this.chatMessage,
        'room_id': this.selectedMessage.id,
        'user_id': this.userId,
        'other_id': otherId,
      };
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      /* バックエンドのAPI呼び出し */
      axios.post(this.restApiBasePath + this.restApiMessagePath, requestBody, reqHeader).then(res => {
        if (res.status.toString() === '200') {
          if (this.file.length === 0) {
            /* リスト再取得 */
            this.reload();
          } else {
            /* 添付ファイル送信処理呼び出し */
            this.sendFile(res.data.message_id);
          }
          /* フォームの初期化 */
          this.chatMessage = "";
        }
      }).catch(e => {
        console.log(e.message);
      });
    },
    /* 添付ファイル送信処理 */
    sendFile(messageId) {
      /* バックエンド向けのデータ作成 */
      let form = new FormData();
      let file = this.file;
      form.append('file', file);
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      /* バックエンドのAPI呼び出し */
      axios.put(this.restApiBasePath + this.restApiMessageFilePath + messageId + '/', form, reqHeader).then(res=>{
        if(res.status.toString() === '200') {
          /* リスト再取得 */
          this.reload();
        }
      }).catch(e => {
        console.log(e.message);
      });
      this.file = [];
    },
    /* 既読処理 */
    readMessage(messageId) {
      /* バックエンド向けのデータ作成 */
      const requestBody = {
        'user_id': this.userId,
      };
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      /* バックエンドのAPI呼び出し */
      axios.put(this.restApiBasePath + this.restApiMessagePath + messageId + '/', requestBody, reqHeader)
          .then(res => {
            if (res.status.toString() !== '200') {
              console.log('API(既読)通信に失敗しました！');
            }
          }).catch(e => {
        console.log(e.message);
      });
    },
    /* 時刻情報の変換処理 */
    parseTime(time) {
      let ts = Date.parse(time);
      const dt = new Date(ts);
      const year = dt.getFullYear();
      const month = dt.getMonth() + 1;
      const days = dt.getDate();
      const hours = this.zeroPadding(dt.getHours(), 2);
      const minutes = this.zeroPadding(dt.getMinutes(), 2);
      return year + "/" + month + "/" + days + " " + hours + ":" + minutes
    },
    getDate(time) {
      return this.parseTime(time).split(' ')[0];
    },
    getTime(time) {
      return this.parseTime(time).split(' ')[1];
    },
    zeroPadding(num, length) {
      return ('0000000000' + num).slice(-length);
    },
    /* ファイル情報の変換処理 */
    parseFile(file) {
      let ret = null;
      if (file) {
        ret = process.env.VUE_APP_URL + '/media/' + file
      }
      return ret
    },
    /* 案件ダイアログ表示 */
    showDisclosure(selectedMessage) {
      let img = selectedMessage.disclosure__user__img ?
          selectedMessage.disclosure__user__img + '?' + Math.random() : '';
      this.disclosure = {
        'title': selectedMessage.disclosure__title,
        'description': selectedMessage.disclosure__description,
        'userId': selectedMessage.disclosure__user__id,
        'userName': selectedMessage.disclosure__user__name,
        'userImg': img,
        'groupName': selectedMessage.disclosure__user__group__name,
        'insertDatetime': this.getDate(selectedMessage.disclosure__insert_datetime),
        'file': selectedMessage.disclosure__file,
        'kind': selectedMessage.disclosure__kind,
        'limit': selectedMessage.disclosure__limit,
      };
      this.openedDisclosureDialog = true;
    },
    /* プロフィール遷移処理 */
    toProfile(userId) {
      this.$router.push('/profile/' + userId).catch(err => {
        console.log(err);
      });
    },
    /* ファイル名を取得 */
    getFileName(file) {
      return file.split('/').slice(-1)[0];
    },
    /* チャットリストのリロード */
    reload() {
      this.chatList = [];
      this.count = 1;
      this.infiniteId += 1;
    },
    /* アップロードファイルのサイズチェック */
    fileSizeCheck() {
      // ファイル削除時にundefinedではなく[]を設定する
      if (!this.file) {
        this.file = [];
      }
      // ファイルサイズ（制限）
      const maxSize = 1024 * 1024 * 3;
      // ファイルサイズが制限内かどうか判別
      this.invalidFileSize = this.file.size > maxSize;
    }
  }
}
</script>

<style scoped>
.chat-message {
  display: block;
}

.insert-date {
  margin: 0 auto;
  padding: 2px 8px;
  border-radius: 16px;
  font-size: 12px;
  opacity: 0.7;
  display: inline-block;
  color: white;
  background-color: black;
}
</style>