<template>
  <v-row no-gutters class="ma-0 pa-0">
    <v-col
        cols="12"
        sm="12"
        class="ma-0 pa-0"
    >
      <v-sheet class="pa-0 ma-0" tile>
        <BpExplore :exploreLabel="exploreLabel" @enterSearchForm="storeSearchString" v-if="$vuetify.breakpoint.smAndUp"/>
        <v-divider />
        <BpMenuBar :searchString="searchString" />
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import BpExplore from '@/components/molecules/bp/BpExplore.vue'
import BpMenuBar from '@/components/molecules/bp/BpMenuBar.vue'

export default {
  name: "MainBpContents",
  data() {
    return {
      /* 検索関連 */
      exploreLabel: 'ユーザ検索',
      searchString: '',
    }
  },
  components: {
    BpExplore,
    BpMenuBar
  },
  methods: {
    /* 検索フォーム入力確定時に値を保存する処理 */
    storeSearchString(eventValue) {
      this.searchString = eventValue;
    }
  }
}
</script>

<style scoped>
</style>