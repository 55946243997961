<template>
  <v-dialog
      v-model="openedGroupProfEditDialog"
      @click:outside="closeThisDialog"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="580">
    <v-card class="pa-10">
      <v-card-title class="headline" :class="$vuetify.breakpoint.xs ? 'pa-2' : 'ma-0 pa-0'">
        <p class="ma-0 pa-0 pl-2" :style="$vuetify.breakpoint.xs ? 'width: 100%; text-align: center !important;' : ''">
          <v-icon v-if="$vuetify.breakpoint.xs"
                  @click="closeThisDialog"
                  :style="$vuetify.breakpoint.xs ? 'position: absolute; top: 12px; left: 12px;' : 'position: relative; top: -2px; left: -4px;'">
            mdi-close-thick
          </v-icon>
          <span v-if="editData.domain">企業情報編集</span>
          <span v-else>背景画像編集</span>
        </p>
      </v-card-title>
      <v-divider></v-divider>
      <v-row class="ma-0 pa-0">
        <v-img
            gradient="rgba(0,0,0,0.6), rgba(0,0,0,0.1)"
            :src="editData.groupImg"
            :max-width="$vuetify.breakpoint.xs ? '100vw' : '500'"
            :min-height="$vuetify.breakpoint.xs ? 'calc( 100vw * calc( 250 / 500 ) )' : '250'"
        >
          <v-btn
              icon
              color="white"
              :style="$vuetify.breakpoint.xs
               ? 'position: relative; top: calc(50% - 18px); left: calc(50% - 18px);'
               : 'position:relative; top:107px; left:242px'"
              @click="onClickImgUpBtn"
          >
            <v-icon large>mdi-plus-circle-outline</v-icon>
          </v-btn>
          <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onImgChanged"
          >
          <ProfileDialogGroupProfImgEdit
              :openedGroupProfImgEditDialog="openedGroupProfImgEditDialog"
              :groupImgSrc="groupImgSrc"
              :groupId="editData.id"
              @closeGroupProfImgEditDialog="closeGroupProfImgEditDialog"
          />
        </v-img>
      </v-row>
      <v-row v-if="invalidSize"
             class="ma-0 pt-4 px-4 red--text"
             style="font-size: 14px;">
        ※ファイルサイズが超過しています(最大3MB)
      </v-row>
      <v-row class="mb-10"></v-row>
      <v-row :class="$vuetify.breakpoint.xs ? 'mx-4' : 'ma-0 pa-0'">
        <v-text-field
            v-if="editData.domain"
            label="会社名"
            :color="formColor"
            :counter="30"
            :maxlength="30"
            v-model="editData.name"
        ></v-text-field>
      </v-row>
      <v-row :class="$vuetify.breakpoint.xs ? 'mx-4' : 'ma-0 pa-0'">
        <v-text-field
            v-if="editData.domain"
            label="URL"
            :color="formColor"
            :counter="100"
            :maxlength="100"
            v-model="editData.url"
        ></v-text-field>
      </v-row>
      <v-card-actions>
        <v-col class="pa-0" style="text-align: center;">
          <v-btn
              color="green accent-4"
              class="mt-6 px-16 py-4 white--text"
              @click="editGroupProfile()"
          >
            保存
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import {mapState} from 'vuex';
import ProfileDialogGroupProfImgEdit from "../../molecules/profile/ProfileDialogGroupProfImgEdit";

export default {
  name: "ProfileDialogGroupProfEdit",
  data() {
    return {
      /* フォーム入力値 */
      editData: {
        id: '',
        domain: '',
        groupImg: '',
        name: '',
        url: ''
      },
      /* 編集前の画像データ */
      groupImgSrc: null,
      /* 送信用の画像データ */
      sendGroupImgSrc: null,
      /* アップロードファイルのサイズ制限の状態 */
      invalidSize: '',
      /* Style関連 */
      formColor: 'green accent-4',
      btnColor: 'green accent-4',
      btnFontColor: 'white--text',
      /* REST API 関連*/
      restApiBasePath: '',
      restApiUserPath: '/api/user/',
      restApiGroupPath: '/api/group/',
      restApiGroupImgPath: '/api/group/img/',
      /* 子ダイアログの状態管理 */
      openedGroupProfImgEditDialog: false,
    }
  },
  components: {
    ProfileDialogGroupProfImgEdit
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedGroupProfEditDialog: Boolean
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token', 'userId'])
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  mounted() {
    /* 初期値の格納 */
    this.getUserInfo();
  },
  methods: {
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog() {
      this.$emit('closeGroupProfEditDialog', false)
    },
    /* 画像編集ダイアログのクローズ */
    closeGroupProfImgEditDialog(clopImg = null) {
      if (clopImg) {
        this.editData.groupImg = clopImg.urlData;
        this.sendGroupImgSrc = clopImg.blobData;
      }
      this.openedGroupProfImgEditDialog = false;
    },
    /* アップロードアイコン押下時にファイルinput発火 */
    onClickImgUpBtn() {
      this.$refs.uploader.click();
    },
    /* アイコン画像アップロード */
    onImgChanged(e) {
      /* 画像取得処理 */
      let uploadFile = e.target.files[0];
      /* サイズチェック */
      const maxSize = 1024 * 1024 * 3;
      this.invalidSize = uploadFile.size > maxSize;
      if (!this.invalidSize) {
        /* URLに変換 */
        this.groupImgSrc = URL.createObjectURL(uploadFile);
        /* 画像編集ダイアログオープン */
        this.openedGroupProfImgEditDialog = true;
      }
    },
    /* ユーザ情報の取得 */
    getUserInfo() {
      /* バックエンドのAPI呼び出し */
      axios.get(this.restApiBasePath + this.restApiUserPath + this.userId + '/').then(res => {
        /* プロフィール情報の格納 */
        this.editData.id = res.data.group__id;
        this.editData.domain = res.data.group__domain;
        this.editData.name = res.data.group__name;
        this.editData.url = res.data.group__url;
        if (res.data.img !== null) {
          this.editData.groupImg = process.env.VUE_APP_URL + '/media/' + res.data.group__img;
        } else {
          this.editData.groupImg = ""
        }
      }).catch(e => {
        console.log(e.message);
      });
    },
    /* プロフィール編集処理 */
    editGroupProfile: async function () {
      await this.sendGroupProfInfo();
      await this.sendGroupProfImg();
      this.getUserInfo();
      this.$emit('closeGroupProfEditDialog', true)
    },
    /* プロフィール情報送信処理 */
    sendGroupProfInfo: async function () {
      /* バックエンド向けのデータ作成 */
      const reqBody = {
        'name': this.editData.name,
        'description': '',
        'url': this.editData.url,
      };
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      /* バックエンドのAPI呼び出し */
      await axios.put(this.restApiBasePath + this.restApiGroupPath + this.editData.id + '/', reqBody, reqHeader)
          .catch(e => {
            console.log(e.message);
          });
    },
    /* プロフィール画像情報送信処理 */
    sendGroupProfImg: async function () {
      /* バックエンド向けのデータ作成 */
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      /* バックエンドのAPI呼び出し */
      await axios.put(this.restApiBasePath + this.restApiGroupImgPath
          + this.editData.id + '/', this.sendGroupImgSrc, reqHeader)
          .catch(e => {
            console.log(e.message);
          });
    },

  }
}
</script>

<style scoped>
</style>