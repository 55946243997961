<template>
  <v-btn
      @click="setBpCancelInfo(otherId)"
      :color="buttonColor"
      :class="buttonFontColor"
      style="position: absolute;"
      :style="$vuetify.breakpoint.xs ? 'right: 16px;' : 'right: 32px;'"
      rounded
      height="40"
      width="120"
      max-width="120"
  >
    <b>BP申請済み</b>
  </v-btn>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: "BpCancellationBtn",
  data() {
    return {
      buttonColor: 'green accent-4',
      buttonFontColor: 'white--text',
      restApiBasePath: '',
      restApiBpPath: '/api/bp/'
    }
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token','userId'])
  },
  props: {
    /* 親コンポーネントから申請相手のユーザIDを受け取り */
    otherId: String
  },
  created() {
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* BP申請取り消し情報送信処理 */
    setBpCancelInfo(otherId) {
      /* バックエンド向けのデータ作成 */
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      /* バックエンドのAPI呼び出し */
      axios.delete(this.restApiBasePath + this.restApiBpPath + this.userId + '/' + otherId + '/', reqHeader)
        .then(() => {
          /* 親コンポーネントへ通知 */
          this.notifyButtonEvent()
        })
        .catch(e => {
          console.log(e.message);
        }
      );
    },
    /* ボタン押下を親コンポーネントへ通知する処理 */
    notifyButtonEvent() {
      this.$emit('enterBpCancelBtn', true)
    }
  }
}
</script>

<style scoped></style>