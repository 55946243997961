<template>
  <div>
    <NaviRight/>
  </div>
</template>

<script>
import NaviRight from '@/components/organisms/navi/pc/PcNaviFooter.vue'

export default {
  name: "NaviFooter",
  components: {
    NaviRight,
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>