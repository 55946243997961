<template>
  <v-dialog
      v-model="opened"
      max-width="400"
  >
    <v-card>
      <v-card-text class="px-6 pt-10 pb-6 card-text">
        ＢＰ関係を解消しますか？
      </v-card-text>
      <v-card-actions class="card-actions">
        <v-col>
          <v-btn
              :color="btnColor"
              :class="btnFontColor"
              @click="setBpCancelInfo"
          >
            <v-icon class="pr-1 icon">
              mdi-hand-right
            </v-icon>
            解消する
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
              :color="cancelBtnColor"
              :class="btnFontColor"
              @click="closeDialog"
          >
            キャンセル
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: "BpDialogDelConform",
  data() {
    return {
      btnColor: 'red',
      cancelBtnColor: 'grey',
      btnFontColor: 'white--text',
      restApiBasePath: '',
      restApiBpPath: '/api/bp/'

    }
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    opened: Boolean,
    /* 親コンポーネントから申請相手のユーザIDを受け取り */
    otherId: String
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token','userId'])
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
  },
  methods: {
    /* BP解消処理 */
    setBpCancelInfo() {
      /* バックエンド向けのデータ作成 */
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      /* バックエンドのAPI呼び出し */
      axios.delete(this.restApiBasePath + this.restApiBpPath + this.userId + '/' + this.otherId + '/', reqHeader)
        .then(() => {
          /* ダイアログを閉じる */
          this.closeDialog()
        })
        .catch(e => {
          console.log(e.message);
        }
      );
    },
    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeDialog() {
      this.$emit('enterCloseBtn', false)
    },
  }
}
</script>

<style scoped>
  /* ダイアログ内のメッセージフォント */
  .card-text {
    font-weight: bold;
    text-align: center;
  }

  /* ダイアログ内のボタンの位置調整 */
  .card-actions {
     text-align: center;
  }

  /* ボタン内のアイコンサイズ調整 */
  .icon {
    font-size: 18px;
  }
</style>