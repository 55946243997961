<template>
  <div class="bpPage">
    <v-main>
      <v-container
          class="ma-0 pa-0"
      >
        <MainBpContents/>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import MainBpContents from '@/components/organisms/main/bp/MainBpContents.vue'

export default {
  metaInfo: {
    title: 'SE-Hub',
    titleTemplate: 'BP管理 | %s',
    meta: [
      { name: "robots", content: "noindex" },
    ]
  },
  name: 'BpPage',
  components: {
    MainBpContents
  }
}
</script>

<style scoped>
</style>