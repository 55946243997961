<template>
  <v-dialog
      v-model="openedSettingsDialog"
      @click:outside="closeThisDialog()"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="600"
  >
    <v-card class="pa-10">
      <v-card-title
          class="headline"
          :class="$vuetify.breakpoint.xs ? 'pt-4 pb-6' : 'mx-0 mt-0 mb-10 pa-0'"
          :style="$vuetify.breakpoint.xs ? 'text-align: center;' : ''">
        <p class="ma-0 pa-0 pl-2" style="width: 100%;" :style="$vuetify.breakpoint.xs ? 'width: 100%; text-align: center;' : ''">
          <v-icon @click="closeThisDialog()" v-if="$vuetify.breakpoint.xs"
                  :style="$vuetify.breakpoint.xs ? 'position: absolute; top: 18px; left: 18px;' : ''">
            mdi-close-thick
          </v-icon>
          設定
        </p>
      </v-card-title>
      <v-card-subtitle class="my-0 py-0">通知設定</v-card-subtitle>
      <v-card-subtitle class="ml-2 my-0 py-0">メッセージ受信</v-card-subtitle>
      <v-card-actions class="my-0 py-0" :class="$vuetify.breakpoint.xs ? 'ml-6' : 'ml-10'">
        <v-row class="my-0 py-0">
          <v-col class="my-0 py-0">
            <v-switch
                v-model="settings.messageSendMail"
                color="green accent-4"
                class="my-4 py-0"
                inset
                :dense="$vuetify.breakpoint.xs"
                :class="$vuetify.breakpoint.xs ? 'small-label' : ''"
                :label="'メールで通知する'"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-subtitle class="ml-2 my-0 py-0">BPリクエスト</v-card-subtitle>
      <v-card-actions class="my-0 py-0" :class="$vuetify.breakpoint.xs ? 'ml-6' : 'ml-10'">
        <v-row class="my-0 py-0">
          <v-col class="my-0 py-0">
            <v-switch
                v-model="settings.bpSendMail"
                color="green accent-4"
                class="my-4 py-0"
                inset
                :dense="$vuetify.breakpoint.xs"
                :class="$vuetify.breakpoint.xs ? 'small-label' : ''"
                :label="'メールで通知する'"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-subtitle class="my-3 py-0">アカウント</v-card-subtitle>
      <v-card-actions class="my-0 py-0" :class="$vuetify.breakpoint.xs ? 'ml-6' : 'ml-10'">
        <v-row class="my-0 py-0">
          <v-col class="my-0 py-0">
            <v-switch
                v-model="settings.canFindName"
                color="green accent-4"
                class="my-1 py-0"
                inset
                :dense="$vuetify.breakpoint.xs"
                :class="$vuetify.breakpoint.xs ? 'small-label' : ''"
                :label="'アカウント検索を有効にする'"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-subtitle class="py-0" :class="$vuetify.breakpoint.xs ? 'mt-4' : 'mt-1'" style="text-align: center;">
        あなたのアカウントのQRコード
      </v-card-subtitle>
      <div class="ma-0 pa-0" style="text-align: center;">
        <vue-qrcode :value="basePath +'/profile/' + userId"
                    :color="{ dark: '#666666', light: '#ffffff' }"/>
      </div>
      <v-card-actions>
        <v-col class="mt-4 mb-0 pb-4 py-0" style="text-align: center;">
          <v-btn
              color=red
              class="white--text"
              :height="$vuetify.breakpoint.xs ? '30' : '40'"
              width="140"
              @click.stop="openedLogoutDialog = true"
          >
            <b>ログアウト</b>
          </v-btn>
        </v-col>
        <NaviDialogLogout :openedLogoutDialog="openedLogoutDialog" @closeLogoutDialog="closeLogoutDialog" />
      </v-card-actions>
      <v-card-subtitle class="mb-0 pb-0">その他</v-card-subtitle>
      <v-card-actions :class="$vuetify.breakpoint.xs ? 'pb-6' : ''">
        <v-col class="mb-2 pa-0" style="text-align: center;">
          <v-card-text
              class="mt-2 pa-0"
              style="color: #cccccc; font-weight: normal; text-decoration: underline; cursor: pointer;"
              @click.stop="openedDelAccountDialog=true"
          >
            <b>アカウントの退会について</b>
          </v-card-text>
        </v-col>
        <NaviDialogDelAccount :openedDelAccountDialog="openedDelAccountDialog" @closeDelAccountDialog="closeDelAccountDialog" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapState, mapMutations } from 'vuex';
import VueQrcode from 'vue-qrcode';
import NaviDialogLogout from '@/components/molecules/navi/NaviDialogLogout.vue'
import NaviDialogDelAccount from '@/components/molecules/navi/NaviDialogDelAccount.vue'

export default {
  name: "NaviDialogSettings",
  data() {
    return {
      /* フォーム入力値 */
      settings: {
        messageSendMail: false,
        bpSendMail: false,
        canFindName: false,
      },
      /* Style関連 */
      formColor: 'green accent-4',
      btnColor: 'green accent-4',
      btnFontColor: 'white--text',
      errMessageColor: 'red--text',
      /* REST API 関連*/
      restApiBasePath: '',
      restApiUserPath: '/api/user/',
      restApiUserSettingsPath: '/api/user/settings/',
      basePath: '',
      /* ダイアログ状態管理 */
      openedLogoutDialog: false,
      openedDelAccountDialog: false
    }
  },
  components: {
    NaviDialogLogout,
    NaviDialogDelAccount,
    VueQrcode
  },
  props: {
    /* 親コンポーネントからダイアログの開閉状態受け取り */
    openedSettingsDialog: Boolean,
  },
  computed: {
    /* Vuexのデータを展開 */
    ...mapState(['token', 'userId', 'globalMainReload'])
  },
  created() {
    /* Base URL の読み込み */
    this.restApiBasePath = process.env.VUE_APP_URL;
    this.basePath = process.env.VUE_APP_URL;
  },
  mounted() {
    /* 設定値情報の取得 */
    if (this.userId) {
      this.getUserInfo();
    }
  },
  watch: {
      'settings.messageSendMail': function(settingsVal){
        this.sendSettings(settingsVal, '1')
      },
      'settings.bpSendMail': function (settingsVal) {
        this.sendSettings(settingsVal, '2')
      },
      'settings.canFindName': function (settingsVal) {
        this.sendSettings(settingsVal, '5')
      },
      'openedSettingsDialog': function () {
        this.getUserInfo();
      }
  },
  methods: {
    /* Vuex展開 */
    ...mapMutations([
      'setGlobalMainReload',
    ]),

    /* ダイアログのクローズを親コンポーネントへ通知する処理 */
    closeThisDialog(doneLogout=false) {
      if (doneLogout) {
        if (this.$route.path !== '/') {
          this.$router.push('/').catch(err => {
            console.log(err)
          });
        }
        this.setGlobalMainReload(!this.globalMainReload);
      }
      this.$emit('doneSettings', doneLogout)
    },
    closeLogoutDialog(doneLogout=false) {
      this.openedLogoutDialog = false;
      if (doneLogout) {
        this.closeThisDialog(doneLogout);
      }
    },
    closeDelAccountDialog(doneLogout=false) {
      this.openedDelAccountDialog = false;
      if (doneLogout) {
        this.closeThisDialog(doneLogout);
      }
    },
    sendSettings(settingsVal, kind) {
      const reqHeader = {
        headers: {
          Authorization: 'JWT' + ' ' + this.token,
        },
      };
      const reqBody = {
        'kind': kind,
        'is_enable': settingsVal,
      };
      axios.put(this.restApiBasePath + this.restApiUserSettingsPath + this.userId + '/', reqBody, reqHeader).then(res => {
        if (res.status.toString() === '200') {
          this.getUserInfo();
        }
      }).catch(e => {
        console.log("error");
        console.log(e.message);
      });
    },
    /* ユーザ情報取得処理 */
    getUserInfo () {
      if (!this.userId) return;

      axios.get(this.restApiBasePath + this.restApiUserPath + this.userId + '/').then(res => {
        this.settings.messageSendMail = res.data.should_send_message;
        this.settings.bpSendMail = res.data.should_send_bp;
        this.settings.canFindName = res.data.can_find_name;
      }).catch(e => {
        console.log("error");
        console.log(e.message);
      });
    },
  }
}
</script>

<style scoped>
</style>

<style>
.v-input--switch.small-label .v-label {
  font-size: 14px;
}
</style>