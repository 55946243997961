<template>
  <div class="index">
    <v-app>
      <NaviHeader/>
      <router-view />
      <NaviFooter/>
    </v-app>
  </div>
</template>

<script>
import NaviHeader from "@/components/organisms/navi/NaviHeader";
import NaviFooter from "@/components/organisms/navi/NaviFooter";

export default {
  name: 'index',
  components: {
    NaviHeader,
    NaviFooter
  }
}
</script>