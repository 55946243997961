<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <v-img v-if="userInfo.groupImg" :src="userInfo.groupImg" :height="$vuetify.breakpoint.xs ? '125' : '250'" />
        <v-img v-else src="@/assets/default_group_img.jpeg" :height="$vuetify.breakpoint.xs ? '125' : '250'" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="ma-5">
        <v-row no-gutters :style="$vuetify.breakpoint.xs ? 'margin-top: -50px;' : 'margin-top: -100px;'">
          <v-col>
            <v-avatar :size="$vuetify.breakpoint.xs ? '80' : '160'" color="white" />
            <v-avatar :size="$vuetify.breakpoint.xs ? '75' : '150'"
                      :style="$vuetify.breakpoint.xs ? 'margin-left: -77.5px' : 'margin-left: -155px'"
                      color="white">
              <v-img v-if="userInfo.icon" :src="userInfo.icon"></v-img>
              <v-icon v-else color="grey darken-1" :style="$vuetify.breakpoint.xs ? 'font-size: 60px;' : 'font-size: 110px;'">
                mdi-account
              </v-icon>
            </v-avatar>
          </v-col>
          <v-spacer/>
          <v-col v-show="this.userId && this.userId === urlUserId" cols="4"
                 :style="$vuetify.breakpoint.xs ? 'margin-top: 50px;' : 'margin-top: 100px; margin-right: 16px; text-align: right;'">
            <v-btn
                color="grey"
                class="white--text"
                :style="$vuetify.breakpoint.xs ? 'margin-left: -60px; margin-bottom: 16px;' : ''"
                :height="$vuetify.breakpoint.xs ? '36' : '40'"
                depressed
                @click="openedProfEditDialog = true"
            >
              <b>プロフィールを編集</b>
            </v-btn>
          </v-col>
          <v-col v-show="this.userId && this.userId !== urlUserId" cols="4"
                 :style="$vuetify.breakpoint.xs ? 'margin-top: 40px; margin-bottom: 16px;' : 'margin-top: 100px; margin-bottom: 32px; text-align: right;'">
            <v-btn
                v-if="(bpStatus === '0') || (bpStatus === '2')"
                color="green accent-4"
                class="text--green text-accent-4"
                height=45
                width=120
                outlined
                rounded
                @click="setBpInfo"
                style="border-width: 2px"
            >
              <b>BP申請する</b>
            </v-btn>
            <v-btn
                v-if="(bpStatus === '1')"
                color="green accent-4"
                class="white--text"
                height=45
                width=120
                rounded
                @click="deleteBpInfo"
            >
              <b>BP申請済み</b>
            </v-btn>
            <v-btn
                v-if="(bpStatus === '3')"

                color="green accent-4"
                class="white--text"
                height=45
                width=120
                rounded
                @click="openedBpDelConformDialog = true"
            >
              <v-icon style="position: absolute; left: 0; font-size: 20px;" color="white">mdi-handshake-outline</v-icon>
              <b>BP</b>
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <h3><span v-if="userInfo.groupName"
                                         style="color: darkslateblue;">{{ userInfo.groupName }}<br/></span>{{ userInfo.name }}</h3>
            <a :href="userInfo.groupUrl" rel="nofollow" target="_blank" class="green--text"> {{ userInfo.groupUrl }}</a>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-6">
          <v-col>
            <p style="white-space: pre-line; word-wrap: break-word;">
              {{ userInfo.description }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <ProfileDialogProfEdit :openedProfEditDialog="openedProfEditDialog" @closeProfEditDialog="closeProfEditDialog" />
    <BpDialogDelConform
        :otherId="this.urlUserId"
        :opened="openedBpDelConformDialog"
        @enterCloseBtn="closeBpDelConformDialog"
    />
    <v-divider/>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState} from 'vuex';
import 'cropperjs/dist/cropper.css';
import ProfileDialogProfEdit from "../../molecules/profile/ProfileDialogProfEdit";
import BpDialogDelConform from '../../molecules/bp/BpDialogDelConform.vue';

export default {
    metaInfo() {
      return {
        title: 'SE-Hub',
        titleTemplate: this.userInfo.name + (this.userInfo.groupName ? '@' + this.userInfo.groupName : '') + 'さん | %s',
        meta: [
          { vmid: 'description', name: 'description', content: this.userInfo.description }
        ]
      }
    },
    name: "MainProfile",
    data() {
      return {
        /* ダイアログの状態管理 */
        openedProfEditDialog: false,
        openedBpDelConformDialog: false,
        /* ユーザ情報 */
        userInfo: {
          name: '',
          description: '',
          icon: '',
          groupName: '',
          groupUrl: '',
          groupImg: ''
        },
        /* URLから取得したユーザーID */
        urlUserId: '',
        /* BP情報 */
        bpStatus: '',
        /* REST API 関連 */
        restApiBasePath: '',
        restApiUserPath: '/api/user/',
        restApiBpPath: '/api/bp/'
      }
    },
    watch: {
      '$route' : function() {
        this.getUserIdFromURL();
        this.getUserInfo();
        this.getBpInfo();
      }
    },
    components: {
      ProfileDialogProfEdit,
      BpDialogDelConform
    },
    computed: {
      /* Vuexのデータを展開 */
      ...mapState(['token','userId'])
    },
    created() {
      /* Base URL の読み込み */
      this.restApiBasePath = process.env.VUE_APP_URL;
    },
    mounted: function () {
      this.getUserIdFromURL();
      this.getUserInfo();
      this.getBpInfo();
    },
    methods: {
      /* プロフィール編集ダイアログのクローズ処理 */
      closeProfEditDialog (doneEdit) {
        /* 編集が行われている場合ユーザ情報再取得 */
        if (doneEdit) {
          this.getUserInfo();
        }
        this.openedProfEditDialog = false;
      },
      /* BP解除確認ダイアログのクローズ処理 */
      closeBpDelConformDialog() {
        this.getBpInfo();
        this.openedBpDelConformDialog = false;
      },
      /* URLからユーザID取得処理 */
      getUserIdFromURL () {
        this.urlUserId = this.$route.params.id;
      },
      /* ユーザー情報取得処理 */
      getUserInfo() {
        /* バックエンドのAPI呼び出し */
        axios.get(this.restApiBasePath + this.restApiUserPath + this.urlUserId + '/').then(res => {
          this.userInfo.name = res.data.name;
          this.userInfo.description = res.data.description;
          if (res.data.img) {
            this.userInfo.icon = process.env.VUE_APP_URL + '/media/' + res.data.img + '?' + Math.random();
          } else {
            this.userInfo.icon = ""
          }
          this.userInfo.groupName = res.data.group__name;
          this.userInfo.groupUrl = res.data.group__url;
          if (res.data.group__img) {
            this.userInfo.groupImg = process.env.VUE_APP_URL + '/media/' + res.data.group__img + '?' + Math.random();
          } else {
            this.userInfo.groupImg = ""
          }
        }).catch(e => {
          console.log(e.message);
        });
      },
      /* BP情報取得処理 */
      getBpInfo() {
        /* バックエンド向けのデータ作成 */
        const reqHeader = {
          headers: {
            Authorization: 'JWT' + ' ' + this.token,
          },
        };
        /* バックエンドのAPI呼び出し */
        axios.get(this.restApiBasePath + this.restApiBpPath + this.userId + '/' + this.urlUserId + '/', reqHeader)
        .then(res => {
          if (res.status.toString() === '200') {
            /* BP情報格納 */
            this.bpStatus = res.data.bp_status;
          }
        }).catch(e => {
          console.log(e.message);
        });
      },
      /* BP追加処理 */
      setBpInfo() {
        /* バックエンド向けのデータ作成 */
        const reqBody = {
          'user_id': this.userId,
          'other_id': this.urlUserId,
        };
        const reqHeader = {
          headers: {
            Authorization: 'JWT' + ' ' + this.token,
          },
        };
        /* バックエンドのAPI呼び出し */
        axios.post(this.restApiBasePath + this.restApiBpPath, reqBody, reqHeader).then(res => {
          if (res.status.toString() === '200') {
            this.getBpInfo()
          }
        }).catch(e => {
          console.log(e.message);
        });
      },
      /* BP解除処理 */
      deleteBpInfo() {
        /* バックエンド向けのデータ作成 */
        const reqHeader = {
          headers: {
            Authorization: 'JWT' + ' ' + this.token,
          },
        };
        /* バックエンドのAPI呼び出し */
        axios.delete(this.restApiBasePath + this.restApiBpPath + this.userId + '/' + this.urlUserId + '/', reqHeader)
        .then(res => {
          if (res.status.toString() === '200') {
            this.getBpInfo()
          }
        }).catch(e => {
          console.log(e.message);
        });
      }
    }
  }
</script>

<style scoped>

</style>